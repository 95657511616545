import {universeTypes} from './universeTypes';

const initialState = {
    items: [],
    selected: null,
    needSave: false,
    shared: false
};


export const universeReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case universeTypes.universeAddItem:
            return{
                ...state,
                items: [...state.items, action.payload]
            }
        
        case universeTypes.universeRemoveItem:
            return{
                ...state,
                items: [...state.items.filter(item => item.tokenId !== action.payload.tokenId)]
            }

        case universeTypes.universeUpdateItem:
            return{
                ...state,
                items: [...state.items.map(item => item.tokenId === action.payload.tokenId ? action.payload : item)]
            }

        case universeTypes.universeSelectItem:
            return{
                ...state,
                selected: action.payload
            }

        case universeTypes.universeReset:
            return {
                ...state,
                items: []
            }

        case universeTypes.universeSetNeedSave:
            return {
                ...state,
                needSave: action.payload
            }
        default:
            return state;
    }
}