import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from 'common/Container'
import { isAddressAdmin } from 'reducers/authReducer/authActions';
import { ArtReview } from './components/ArtReview';
import { ArtRarity } from './components/ArtRarity';
import { loadArtEngineAmount, loadArtistSelected, loadNebulaTraits, loadNebulaTraitTraits, loadOutside, loadSound, loadStar } from 'reducers/adminReducer/adminActions';
import { NFTNext5 } from './components/NFTNext5';
import { IssuedNFT } from './components/IssuedNFT';
import { TraitsReview } from './components/TraitsReview';
import { ArtistSelectedReview } from './components/ArtistSelectedReview';

export const Admin = () => {
    const {address} = useSelector(state => state.walletProvider);
    const [isAdmin, setIsAdmin] = useState(false);
    const {adminActiveLink} = useSelector(state => state.ui);
    
    

    const dispatch = useDispatch();

    const checkAdmin = async () => {
        const isIt = await isAddressAdmin(address);
        setIsAdmin(isIt);
    }

    useEffect(() => {
      dispatch(loadNebulaTraits());
      dispatch(loadNebulaTraitTraits());
      dispatch(loadOutside());
      dispatch(loadSound());
      dispatch(loadStar());
      dispatch(loadArtEngineAmount())
      dispatch(loadArtistSelected())
    }, [])
    

    useEffect(() => {
      if (address){
        checkAdmin();
      } else {
        setIsAdmin(false);
      }
    }, [address])

    
    const getComponent = () => {
        switch(adminActiveLink){
            case 'artReview':
                return (<ArtReview/>)
            case 'artistSelected':
                return (<ArtistSelectedReview/>)
            case 'nftNext5':
              return (<NFTNext5/>)
            case 'issuedNFT':
              return (<IssuedNFT/>)
            case 'traits':
                return (<TraitsReview/>)
            default:
                return (<ArtReview/>)
        }
    }
    
  return (
    <Container>
        {(isAdmin) ? (
            <div>
                {getComponent()}
            </div>
        ) : (
            <div className='center-screen'>
              Sorry but you are not an admin. 
            </div>
        )}
    </Container>
  )
}
