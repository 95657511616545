import React, { useState } from 'react'
import { Grid, Link, Typography, Box, Switch, FormControlLabel } from '@mui/material'
import { useTheme } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Container from 'common/Container';
import { uiSetView, uiShowAlert } from 'reducers/uiReducer/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import { Lab } from './components/Lab';
import { useEffect } from 'react';
import { ReadOnly } from './components/ReadOnly';
import { saveUniverse } from 'reducers/universeReducer/universeActions';

export const MyUniverse = () => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const {items} = useSelector(state => state.blockchain);
  const {items: universeItems, needSave} = useSelector(state => state.universe);
  const {address} = useSelector(state => state.walletProvider);
  const [checked, setchecked] = useState(true)

  useEffect(() => {
    if (universeItems.length === 0)
      setchecked(true)
    else
      setchecked(false)
  }, [])
  
const handleOnCheckedChange = async() => {
  if (checked && needSave){
    saveUniverse(address, {items: universeItems})
    dispatch(uiShowAlert('success', 'My Universe', 'Saved successfully.'))
  }

  if (checked && universeItems.length > 0){
    setchecked(checked => !checked)
    return;
  }

  if (!checked){
    setchecked(checked => !checked)
    return;
  }
  
}
  return (
    <div className='space'>
      <Container>
        <Box paddingTop={5} onClick={() => {dispatch(uiSetView('home'))}}>
          <Link 
            underline="hover"
          >
            <Typography
              fontFamily={theme.palette.font.headline_regular}
              letterSpacing='0.2em'
              variant='h6'
              textTransform={'uppercase'}
            >
              <ArrowBackIosIcon/> Back
            </Typography>
          </Link>
        </Box>
      </Container>
        <Grid 
          container 
          direction={'column'} 
          justifyContent="center"
          align='center'
          spacing={4}
          alignItems="center"
        >
          <Grid item>
            <Typography
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.2em'
                variant='h4'
                align='center'
                textTransform={'uppercase'}
            >
                Create your Stellaluna universe
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              fontFamily={theme.palette.font.content}
              variant='body'
            >
              Drag and Drop your Nebula Sphere onto the screen, reposition it or change it to a size you want.<br/>
              When you're happy you can save you STELLALUNA UNIVERSE or export the image to share it on your social channels to spread some love.<br/><br/>

              When you decide to collect more or change some of your Nebula Spheres, you can also update your STELLALUNA UNIVERSE.
            </Typography>
          </Grid>
            {(items.length > 0) ? (
              <div>
                <Grid item paddingY={2}>
                  <FormControlLabel control={<Switch color='warning' checked={checked} onChange={handleOnCheckedChange}/>} label="Edit mode" />
                </Grid>
                {(checked) ? (
                  <Grid item>
                    <Lab/>
                  </Grid>
                ) : (
                  <Grid item>
                    <ReadOnly/>
                  </Grid>
                )}
              </div>
            ) : (
              <Grid item>
                <Box className='center-screen'>
                  You don't any STELLALUNA. Verify Opensea collection STELLALUNA to aquire them.
                </Box>
              </Grid>
            )}
        </Grid>
    </div>       
  )
}
