import React, {useState, useEffect} from 'react'
import { Grid, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { StellaLunaCard } from './components/StellaLunaCard';
import Container from 'common/Container';
import { uiSetView } from 'reducers/uiReducer/uiActions';
import { Box } from '@mui/system';
import { Address } from './components/Address';
import { isWithPhysical } from 'reducers/blockchainReducer/blockchainActions';

export const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const {items} = useSelector(state => state.blockchain);
  const {address} = useSelector(state => state.walletProvider);

  const [withPhysical, setWithPhysical] = useState(false);

  const getIsWithPhysical = async () => {
    const result = await isWithPhysical(address);
    if (result)
      setWithPhysical(true)
  }
  useEffect(() => {
    if (address)
      getIsWithPhysical();
  }, [address])
  

  return (
    <div className='space'>
      <Container>
        <Box paddingTop={5} onClick={() => {dispatch(uiSetView('home'))}}>
          <Link 
            underline="hover"
          >
            <Typography
              fontFamily={theme.palette.font.headline_regular}
              letterSpacing='0.2em'
              variant='h6'
              textTransform={'uppercase'}
            >
              <ArrowBackIosIcon/> Back
            </Typography>
          </Link>
        </Box>

        <Grid 
          container 
          direction={'column'} 
          justifyContent="center"
          spacing={4}
          alignItems="center"
        >
          {(withPhysical) && (
            <Grid item>
              <Address/>
            </Grid>
          )}
          <Grid item>
            <Typography
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.2em'
                variant='h4'
                textTransform={'uppercase'}
            >
                Dashboard
            </Typography>
          </Grid>

          {(items.length > 0) ? (
            <Grid item>
              <Grid 
                container 
                direction={'row'}
                spacing={2}
                justifyContent="center"
                alignItems="center"  
              >
                {items.map((item, index) => (
                  
                  <Grid item key={index}>
                    {(item.metadata) && (
                      <StellaLunaCard item={item} key={item.tokenId}/>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid item className='center-screen'>
              You don't any STELLALUNA. Verify Opensea collection STELLALUNA to aquire them.
            </Grid>
          )}

        </Grid>
      </Container>
    </div>       
  )
}
