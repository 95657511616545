import React, {useState, useEffect, useRef} from 'react'
import { Box, CircularProgress, Grid, Pagination, Typography, useTheme } from '@mui/material'
import { db } from 'config/firebase'
import { useDispatch, useSelector } from 'react-redux'
import { uiShowAlert } from 'reducers/uiReducer/uiActions'
import { AdminCard } from '../AdminCard'


export const ArtReview = () => {
    const theme = useTheme()
    const pageSize = 10;
    const {artAmount} = useSelector(state => state.admin)
    const amountPages = Math.ceil(artAmount / pageSize);
    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState([])
    const startAt = useRef(1)
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()

    const loadArt = async () => {
        setIsLoading(true)
        try{
            const query = db.collection('drop1').doc('artEngine').collection('metadata').limit(pageSize).orderBy('artId', 'asc').startAt(startAt.current);
            const snapshot = await query.get();
            const result = [];
            for (const doc of snapshot.docs){
                const item = doc.data();
                result.push(item)
            }

            setItems(result);
        } catch ( error ) {
            console.log(error);
            dispatch(uiShowAlert('error', 'We are sorry', 'There was an error loading the art pieces. Please retry later.'));
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        loadArt();
    }, [])

    const paginationOnChange = async (newPage) => {
        setPage(newPage);
        startAt.current = (newPage -1 ) * pageSize;
        await loadArt()
    }
    
  return (
    <div>
        <Box textAlign={'center'} paddingBottom={5}>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='h5'
                align='center'
            >
                Review each art piece before it becomes an NFT
            </Typography>
        </Box>

        <Grid
            container
            direction='column'
            justifyContent={'center'}
            alignItems='center'
        >
            <Grid item paddingBottom={5}>
                <Pagination count={amountPages} variant="outlined" color="primary" onChange={(e, page) => paginationOnChange(page)} page={page}/>
            </Grid>

            <Grid item>
                {(isLoading) ? (
                    <div className='screen-center'>
                        <CircularProgress/>
                    </div>
                ) : (
                    <Grid
                        container
                        direction='row'
                        justifyContent={'center'}
                        alignItems='center'
                        spacing={2}
                    >
                        {items.map((item, index) => (
                            <Grid item key={index}>
                                <AdminCard item={item}/>
                            </Grid>
                        ) )}

                    </Grid>
                )}
            </Grid>

            <Grid item paddingTop={5}>
                <Pagination count={amountPages} variant="outlined" color="primary" onChange={(e, page) => paginationOnChange(page)} page={page}/>
            </Grid>
        </Grid>
    </div>
  )
}
