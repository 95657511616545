import React from 'react'
import { useTheme } from '@mui/material'
import { Grid, Typography } from '@mui/material'
import {isMobile} from 'react-device-detect';
import ReactPlayer from 'react-player'
import video from 'images/mintPhases/Gallery.mp4';

export const MintPhases = () => {
    const theme = useTheme();
    
  return (
    <div>
        <Typography
            fontFamily={theme.palette.font.headline_regular}
            letterSpacing='0.2em'
            variant='h4'
            textTransform={'uppercase'}
            textAlign={'center'}
            paddingBottom={5}
        >
            MINT PHASES
        </Typography>

        <Grid
            container
            direction={(!isMobile) ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={10}
        >
            <Grid item xs={6}>
                <Grid
                    container
                    direction='column'
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='h6'
                            fontWeight={700}
                            gutterBottom
                            textAlign={'center'}
                        >
                            PHASE 1 / TBA<br/>
                            WL SALE
                        </Typography>

                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body1'
                            textAlign={'center'}
                        >
                            Everyone with a WL spot is guaranteed to mint.<br/><br/>
                            Option 1: Mint up to 4 NFTs<br/>
                            Option 2: Mint 5 NFTs to receive free Artist Signed Physical Print<br/>
                            Option 3: Mint 1 NFT + Artist Signed Physical Print<br/><br/>

                            Prices TBA. Max 5 per transaction. No max per wallet.
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='h6'
                            fontWeight={700}
                            gutterBottom
                            textAlign={'center'}
                        >
                            PHASE 2 / TBA<br/>
                            PUBLIC SALE
                        </Typography>

                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body1'
                            textAlign={'center'}
                        >
                            Everyone can mint STELLALUNA NFTs for TBA ETH.<br/><br/>
                            Option 1: Mint up to 4 NFTs<br/>
                            Option 2: Mint 5 NFTs to receive free Artist Signed Physical Print<br/>
                            Option 3: Mint 1 NFT + Artist Signed Physical Print<br/><br/>

                            Prices TBA. Max 5 per transaction. No max per wallet.
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item xs={12}>
                <Typography
                    fontFamily={theme.palette.font.content}
                    variant='h6'
                    fontWeight={700}
                    textTransform='uppercase'
                    textAlign={'center'}
                >
                    The Snapshot will be taken on TBA
                </Typography>
            </Grid>

        </Grid>

        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            paddingTop={4}
            spacing={2}
        >
            <Grid item>
                <ReactPlayer
                    url={video}
                    playing
                    loop
                    muted
                    width='100%'
                    height='100%'
                />
            </Grid>
        </Grid>

    </div>
  )
}
