import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, CircularProgress } from '@mui/material';
import { LunaAnimation } from 'views/NFT/three/LunaAnimation';
import { getBase64FromUrl } from 'utils/base64';

import useWindowDimensions from 'hooks/useWindowDimensions'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

export const StellaLunaDialog = props => {
    const { height, width } = useWindowDimensions();
    const {item, open, setOpen} = props;
    const [art, setArt] = useState();

    const handleClose = () => {
        setOpen(false);
      };

    const loadAnimation = async () => {
        
        const {nebula, outside, sound, star, color} = item;
        const nebulaBlob = await getBase64FromUrl(nebula);
        const outsideBlob = await getBase64FromUrl(outside);
        const soundBlob = await getBase64FromUrl(sound);
        const starBlob = await getBase64FromUrl(star);

        setArt({nebula: nebulaBlob, outside: outsideBlob, sound:soundBlob, star:starBlob, color})
    }
    
    useEffect(() => {
      if (item)
        loadAnimation()
    }, [item])
    
    

  return (
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} elevation={0}>
          <Toolbar style={{backgroundColor:'black'}} >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {(art) ? (
            <Box>
                <LunaAnimation 
                    nebula={art.nebula} 
                    outside={art.outside} 
                    sound={art.sound} 
                    star={art.star} 
                    color={art.color} 
                    controls={open} 
                    customHeight={height} 
                    customWidth={width}
                    customPlay={open}
                />
            </Box>

        ) : (
            <Box className='center-screen'>
                <CircularProgress/>
            </Box>
        )}
      </Dialog>
  )
}

StellaLunaDialog.propTypes = {
    item: PropTypes.object,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
}
