import React from 'react'

import ReactPlayer from 'react-player'
import { isMobile } from 'react-device-detect';
import video from 'images/interactive/Stellaluna_40secs.mp4';
import preview from 'images/interactive/video_thumbnail.jpg';
import { Box } from '@mui/material';


export const Interactive = () => {
  return (
    <Box height={'600px'} stlye={{zIndex:10}} marginTop={(isMobile) ? 25 : -2}>
        <ReactPlayer 
            url={video}
            light = {preview}
            playing
            width='100%'
            height='100%'
            controls
        />
    </Box>
  )
}
