import React, {useEffect, useState} from 'react'
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AdminCard } from '../AdminCard';
import { Trait } from './components/Trait/Trait';
import { getArtByTrait } from 'reducers/adminReducer/adminActions';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';

export const TraitsReview = () => {
    const {nebula, nebulaTrait, sound, outside, star, traitFilter, artistSelected} = useSelector(state => state.admin);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const theme = useTheme();

    const findDuplicates = (arr) => {
        let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
        // JS by default uses a crappy string compare.
        // (we use slice to clone the array so the
        // original array won't be modified)
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
          if (sorted_arr[i + 1] == sorted_arr[i]) {
            results.push(sorted_arr[i]);
          }
        }
        return results;
      }

    const getTraitFilter = async () => {
        try {
            setLoading(true)
            if (traitFilter.length == 1){
                const result = await getArtByTrait(traitFilter[0]);
                setItems(result)
                return;
            } else {
                const flatted = traitFilter.flat();
                const duplicates = findDuplicates(flatted)
                
                if (duplicates.length > 0){
                    const result = await getArtByTrait(duplicates);
                    setItems(result)
                } else {
                    setItems([])
                }
            }
        } catch ( error ){
            dispatch(uiShowAlert('error', 'Error', 'We have been unable to retrieve the art pieces. Refresh and try again please.'));
            console.log(error);
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
      if (traitFilter.length > 0){
        getTraitFilter();
      } else {
        setItems([]);
      }
    }, [traitFilter])
    

  return (
    <div>
        <Box textAlign={'center'} paddingBottom={5}>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='h5'
                align='center'
            >
                Trait distribution
            </Typography>

            <Typography
                fontFamily={theme.palette.font.content}
                variant='body'
                align='center'
                gutterBottom
            >
                Filter Art pieces by trait type and values
            </Typography>
        </Box>

        <Grid container direction={'row'} alignItems="flex-start" justifyContent="space-between">
            <Grid item xs={3}>
                <Grid
                    container
                    direction={'column'}
                    justifyContent='center'
                    alignItems={'center'}
                    spacing={2}
                >
                    <Grid item width={'100%'}>
                        <Trait name='Nebula' items={nebula}/>
                    </Grid>

                    <Grid item width={'100%'}>
                        <Trait name='Nebula Trait' items={nebulaTrait}/>
                    </Grid>
                
                    <Grid item width={'100%'}>
                        <Trait name='Sound' items={sound}/>
                    </Grid>

                    <Grid item width={'100%'}>
                        <Trait name='Outside' items={outside}/>
                    </Grid>

                    <Grid item width={'100%'}>
                        <Trait name='Star' items={star}/>
                    </Grid>

                    <Grid item width={'100%'}>
                        <Trait name='ArtistSelected' items={artistSelected}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={9} textAlign='center'>
                {(loading) ? (
                    <div className='center-screen'>
                        <CircularProgress/>
                    </div>
                ) : (
                    <div>
                        {(items.length > 0) ? (
                            <Grid container direction={'row'}>
                                {items.map((item, index) => (
                                    <Grid item key={index}>
                                        <AdminCard item={item}/>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <div >0 results</div>
                        )}
                    </div>
                )}
            </Grid>
        </Grid>

    </div>
  )
}
