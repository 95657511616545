import { db } from 'config/firebase';
import {adminTypes} from './adminTypes';

export const loadNebulaTraits = () => {
    return (dispatch) => {
        try {
            db.collection("drop1").doc("artEngine").collection('Nebula').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const trait = change.doc.data();
                    trait.name = change.doc.id;

                    if (change.type === "added") {
                        dispatch(addNebulaTrait(trait))
                    }
                    if (change.type === "modified") {
                        dispatch(updateNebulaTrait(trait))
                    }
                });
            });            
        } catch ( error ){
            // TODO what should we do?
            console.log(error);
        }
    }
}

const addNebulaTrait = (trait) => ({
    type: adminTypes.adminAddNebula,
    payload: trait
})

const updateNebulaTrait = (trait) => ({
    type: adminTypes.adminUpdateNebula,
    payload: trait
})

export const loadNebulaTraitTraits = () => {
    return (dispatch) => {
        try {
            db.collection("drop1").doc("artEngine").collection('Nebula trait').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const trait = change.doc.data();
                    trait.name = change.doc.id;

                    if (change.type === "added") {
                        dispatch(addNebulaTraitTrait(trait))
                    }
                    if (change.type === "modified") {
                        dispatch(updateNebulaTraitTrait(trait))
                    }
                });
            });            
        } catch ( error ){
            // TODO what should we do?
            console.log(error);
        }
    }
}

const addNebulaTraitTrait = (trait) => ({
    type: adminTypes.adminAddNebulaTrait,
    payload: trait
})

const updateNebulaTraitTrait = (trait) => ({
    type: adminTypes.adminUpdateNebulaTrait,
    payload: trait
})


export const loadOutside = () => {
    return (dispatch) => {
        try {
            db.collection("drop1").doc("artEngine").collection('Outside').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const trait = change.doc.data();
                    trait.name = change.doc.id;

                    if (change.type === "added") {
                        dispatch(addOutsideTrait(trait))
                    }
                    if (change.type === "modified") {
                        dispatch(updateOutsideTrait(trait))
                    }
                });
            });            
        } catch ( error ){
            // TODO what should we do?
            console.log(error);
        }
    }
}

const addOutsideTrait = (trait) => ({
    type: adminTypes.adminAddOutside,
    payload: trait
})

const updateOutsideTrait = (trait) => ({
    type: adminTypes.adminUpdateOutside,
    payload: trait
})

export const loadSound = () => {
    return (dispatch) => {
        try {
            db.collection("drop1").doc("artEngine").collection('Sound').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const trait = change.doc.data();
                    trait.name = change.doc.id;

                    if (change.type === "added") {
                        dispatch(addSoundTrait(trait))
                    }
                    if (change.type === "modified") {
                        dispatch(updateSoundTrait(trait))
                    }
                });
            });            
        } catch ( error ){
            // TODO what should we do?
            console.log(error);
        }
    }
}

const addSoundTrait = (trait) => ({
    type: adminTypes.adminAddSound,
    payload: trait
})

const updateSoundTrait = (trait) => ({
    type: adminTypes.adminUpdateSound,
    payload: trait
})

export const loadStar = () => {
    return (dispatch) => {
        try {
            db.collection("drop1").doc("artEngine").collection('Star').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const trait = change.doc.data();
                    trait.name = change.doc.id;

                    if (change.type === "added") {
                        dispatch(addStarTrait(trait))
                    }
                    if (change.type === "modified") {
                        dispatch(updateStarTrait(trait))
                    }
                });
            });            
        } catch ( error ){
            // TODO what should we do?
            console.log(error);
        }
    }
}

const addStarTrait = (trait) => ({
    type: adminTypes.adminAddStar,
    payload: trait
})

const updateStarTrait = (trait) => ({
    type: adminTypes.adminUpdateStar,
    payload: trait
})

export const adminSetEnabled = (artId, checked) => {
    return db.collection('drop1').doc('artEngine').collection('metadata').doc(artId.toString()).update({enabled: checked});
}

export const getNext5NFT = async () => {
    const query = db.collection('drop1').doc('artEngine').collection('metadata')
        .where('enabled', '==', true)
        .where('available', '==', true)
        .where('artReady', '==', true)
        .where('artProcessReady', '==', true)
        .orderBy('artId', 'asc')
        .limit(5);
    const response = await query.get();
    const result = [];
    for (const doc of response.docs){
        const item = doc.data();
        result.push(item);
    }
    return result;
}

export const getIssuedNFT = async () => {
    const response =  await db.collection('drop1').doc('nft').collection('metadata').get();
        
    const result = [];
    for (const doc of response.docs){
        const item = doc.data();
        result.push(item);
    }
    return result;

}

export const traitAddFilter = (filter) => ({
    type: adminTypes.adminTraitFilterAdd,
    payload: filter
})

export const traitRemoveFilter = (filter) => ({
    type: adminTypes.adminTraitFilterRemove,
    payload: filter
})


export const getArtByTrait = async (newFilter) => {
    const myFilter = newFilter.slice(0, 10)
    const query =  db.collection('drop1').doc('artEngine').collection('metadata').where('artId', 'in', myFilter);
    const response = await query.get();
    const result = [];
    for (const doc of response.docs){
        const item = doc.data();
        result.push(item);
    }
    return result;
}



export const loadArtEngineAmount = () => {
    return async (dispatch) => {
        const snapshot = await db.collection('drop1').doc('artEngine').get();
        if (snapshot.exists){
            const {amount} = snapshot.data();
            dispatch(setArtAmount(amount))
            return;
        }
    
        dispatch(setArtAmount(0))
    }
}

const setArtAmount = (amount) => ({
    type: adminTypes.adminSetArtAmount,
    payload: amount
})

export const adminSetArtistSelected = async (artId, enabled, artistSelected) => {
    const traitRef = db.collection('drop1').doc('artEngine').collection('artistSelected').doc(artistSelected);
    const ref = db.collection('drop1').doc('artEngine').collection('metadata').doc(artId.toString());
    const snapshot = await ref.get();
    const item = snapshot.data();

    const traitSnapshot = await traitRef.get();

    if (enabled){
        await ref.update({attributes: [...item.attributes, {trait_type: 'artistSelected', value: artistSelected}]})
        if (traitSnapshot.exists){
            const trait = traitSnapshot.data();
            await traitRef.update({amount: trait.amount + 1, usedIn: [...trait.usedIn, artId]})
        } else {
            await traitRef.set({amount: 1, usedIn: [artId]})
        }
    } else {
        await ref.update({attributes: [...item.attributes.filter(val => val.trait_type !== 'artistSelected')]})
        const trait = traitSnapshot.data();
        await traitRef.update({amount: trait.amount - 1, usedIn: [...trait.usedIn.filter(val => val !== artId)]})
    }


}

export const loadArtistSelected = () => {
    return (dispatch) => {
        try {
            db.collection("drop1").doc("artEngine").collection('artistSelected').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const trait = change.doc.data();
                    trait.name = change.doc.id;

                    if (change.type === "added") {
                        dispatch(addArtistSelectedTrait(trait))
                    }
                    if (change.type === "modified") {
                        dispatch(updateArtistSelectedTrait(trait))
                    }
                });
            });            
        } catch ( error ){
            // TODO what should we do?
            console.log(error);
        }
    }
}

const addArtistSelectedTrait = (trait) => ({
    type: adminTypes.adminAddArtistSelected,
    payload: trait
})

const updateArtistSelectedTrait = (trait) => ({
    type: adminTypes.adminUpdateArtistSelected,
    payload: trait
})