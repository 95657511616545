import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TraitCheckbox } from '../TraitCheckbox';

export const Trait = props => {
    const {name, items} = props;

    const sortByTraitAmount = (a,b) => {
        if (a.amount > b.amount)
            return -1
        else 
            return 1
    }

  return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{backgroundColor: 'white', color:'black'}}
            >
                <Typography>{name}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container direction={'column'} spacing={2} >

                </Grid>
                {items.sort(sortByTraitAmount).map((item,index) => (
                    <Grid item key={index} >
                        <TraitCheckbox item={item}/>
                    </Grid>
                ))}
            </AccordionDetails>
        </Accordion>
  )
}

Trait.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
}
