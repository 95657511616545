import {blockchainTypes} from './blockchainTypes';

const initialState = {
    stats: {minted: 0, total: 'TBA', withoutPhysical: '170000000000000000', withPhysical: '970000000000000000', date: '24th February, 9am EST' },
    items: [],
};


export const blockchainReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case blockchainTypes.loadStats:
            return {
                ...state,
                stats: action.payload
            }

        case blockchainTypes.ItemAdd:
            return {
                ...state,
                items: [...state.items, action.payload]
            }
        
        case blockchainTypes.ItemUpdate:
            return {
                ...state,
                items: [...state.items.map(val => val.tokenId === action.payload.tokenId ? action.payload : val)]
            }

        case blockchainTypes.ItemRemove:
            return {
                ...state,
                items: [...state.items.filter(val => val.tokenId !== action.payload.tokenId)]
            }
        
            case blockchainTypes.ItemEmpty:
            return {
                ...state,
                items: []
            }

        default:
            return state;
    }
}