import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect';
import { Box, useTheme, Card, CardActionArea, CardContent, CardMedia, Typography, Switch } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { StellaLunaDialog } from 'views/Dashboard/components/StellaLunaDialog';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import { adminSetArtistSelected, adminSetEnabled } from 'reducers/adminReducer/adminActions';
import { getBase64FromUrl } from 'utils/base64';

export const AdminCard = props => {
  const {item} = props;
  const {nebula, nebulaTrait, outside, sound, star, artAmount} = useSelector(state => state.admin);
  const dispatch = useDispatch();
  const [image, setImage] = useState(item.static)

  const loadGif = async () => {
    const base64 = await getBase64FromUrl(item.gif);
    setImage(base64);
  }
  useEffect(() => {
    loadGif();
  }, [])
  

const [open, setOpen] = useState(false)
  const theme = useTheme();
  const {attributes} = (item.attributes) ? item : item.metadata;
  const [checked, setChecked] = useState(item.enabled)
  const [artistChecked, setArtistChecked] = useState((item.attributes.find(val => val.trait_type === 'artistSelected')) ? true : false)
  
  const handleArtistSelectedChange = async () => {
    try{
        await adminSetArtistSelected(item.artId, !artistChecked, 'Nois7' )
        setArtistChecked(artistChecked => !artistChecked);
      } catch ( error ){
        console.log(error);
        dispatch(uiShowAlert('warning', 'Artist Selected', 'We have been unable to change the Artist  Selected property of the art piece. Refresh and try again please.'))
      }
    
  }

  const getTraitAmount = (trait) => {
    let myTrait = {amount: 'NA'}
    if (trait.trait_type === 'Nebula')
      myTrait = nebula.find(val => val.name === trait.value);

    if (trait.trait_type === 'Nebula trait')
      myTrait = nebulaTrait.find(val => val.name === trait.value);

    if (trait.trait_type === 'Outside')
      myTrait = outside.find(val => val.name === trait.value);

    if (trait.trait_type === 'Sound')
      myTrait = sound.find(val => val.name === trait.value);

    if (trait.trait_type === 'Star')
      myTrait = star.find(val => val.name === trait.value);

      const percentage = (myTrait?.amount * 100) / artAmount
    
    return (<span>&nbsp;#{myTrait?.amount} times - {percentage.toFixed(1)}%</span>)
  }

  const handleEnabledChange = async () => {
    try {
      await adminSetEnabled(item.artId, !checked )
      setChecked(checked => !checked);
    } catch ( error ){
      console.log(error);
      dispatch(uiShowAlert('warning', 'Enabled', 'We have been unable to change the enabled property of the art piece. Refresh and try again please.'))
    }
  }
  return (
    <div>
      <Card elevation={2} style={{width: (isMobile) ? '100%' : '400px'}}>
        <CardActionArea onClick={() => setOpen(true)}>
          <CardMedia >
            <img src={image} alt={item.tokenId} width='400px' loading='lazy'/>
          </CardMedia>

          <CardContent>
            <Typography
              fontFamily={theme.palette.font.content}
              variant='h6'
              fontWeight={700}
              textAlign={'center'}
              textTransform='uppercase'
            >
              {(item.tokenId) ? `Token Id ${item.tokenId}` : `Art Id ${item.artId}`}
            </Typography>

            {attributes.map((val, index) => (
              <Box display='flex' justifyContent={'center'} key={index}>
                <Typography 
                  fontFamily={theme.palette.font.content}
                  display='inline'
                  fontWeight={700}
                  variant='body1'
                  align='center'
                >
                  {val.trait_type}:&nbsp;
                </Typography>

                <Typography 
                fontFamily={theme.palette.font.content}
                display='inline'
                variant='body1'
                align='center'
              >
                {val.value}

                {getTraitAmount(val)}
              </Typography>
            </Box>
            ))}
          </CardContent>

        </CardActionArea>
        <Box display='flex' justifyContent={'center'}>
          <Typography 
              fontFamily={theme.palette.font.content}
              display='inline'
              variant='body1'
              fontWeight={700}
              align='center'
            >
              Enabled: <Switch  color="warning" checked={checked}  onChange={handleEnabledChange} />
              <br/>
              Artist Selected: <Switch  color="warning" checked={artistChecked}  onChange={handleArtistSelectedChange} />
              <br/>
              Available: <Switch  color="warning" checked={item.available} disabled />
          </Typography>
        </Box>
      </Card>
      <StellaLunaDialog item={item} open={open} setOpen={setOpen}/>
    </div>
  )
}

AdminCard.propTypes = {
  item: PropTypes.object.isRequired
}
