import React, {useState, useEffect} from 'react'
import { Box, CircularProgress, Grid, Pagination, Typography, useTheme } from '@mui/material'
import { db } from 'config/firebase'
import { useDispatch } from 'react-redux'
import { uiShowAlert } from 'reducers/uiReducer/uiActions'
import { AdminCard } from '../AdminCard'


export const ArtistSelectedReview = () => {
    const theme = useTheme()
    const pageSize = 10;
    const [items, setItems] = useState([])
    const [artAmount, setArtAmount] = useState(0)
    const [amountPages, setAmountPages] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [lastDoc, setLastDoc] = useState()
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()

    

    useEffect(() => {
        if (artAmount > 0)
        setAmountPages(Math.ceil(artAmount / pageSize))
    }, [artAmount])
    
    const getArtAmount = async () => {
        const attribute = {trait_type: 'artistSelected', value: 'Nois7'}
        const query = db
            .collection('drop1')
            .doc('artEngine')
            .collection('metadata')
            .where('attributes', 'array-contains', attribute)
            .where('available', '==', true)
            .orderBy('artId', 'asc')
        const snapshot = await query.get();
        console.log('artAmount', snapshot.docs.length);
        setArtAmount(snapshot.docs.length)
    }

    const loadArt = async (ahead) => {
        setIsLoading(true)
        try{
            const attribute = {trait_type: 'artistSelected', value: 'Nois7'}
            let query = db
                .collection('drop1')
                .doc('artEngine')
                .collection('metadata')
                .where('attributes', 'array-contains', attribute)
                .where('available', '==', true)
                .limit(pageSize)
                .orderBy('artId', 'asc')
                if (ahead)
                    query = query.startAfter((lastDoc) ? lastDoc : 0);
                else
                    query = query.endBefore((lastDoc) ? lastDoc : 0);
            const snapshot = await query.get();
            const result = [];
            setLastDoc(snapshot.docs[pageSize - 1])
            for (const doc of snapshot.docs){
                const item = doc.data();
                result.push(item)
            }

            setItems(result);
        } catch ( error ) {
            console.log(error);
            dispatch(uiShowAlert('error', 'We are sorry', 'There was an error loading the art pieces. Please retry later.'));
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getArtAmount();
        loadArt(true);
    }, [])

    const paginationOnChange = async (newPage) => {
        if (newPage > page)
            await loadArt(true)
        else
            await loadArt(false)
        setPage(newPage);
        
    }
    
  return (
    <div>
        <Box textAlign={'center'} paddingBottom={5}>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='h5'
                align='center'
            >
                Review each art piece before it becomes an NFT
            </Typography>
        </Box>

        <Grid
            container
            direction='column'
            justifyContent={'center'}
            alignItems='center'
        >
            <Grid item paddingBottom={5}>
                <Pagination count={amountPages} variant="outlined" color="primary" onChange={(e, page) => paginationOnChange(page)} page={page}/>
            </Grid>

            <Grid item>
                {(isLoading) ? (
                    <div className='screen-center'>
                        <CircularProgress/>
                    </div>
                ) : (
                    <Grid
                        container
                        direction='row'
                        justifyContent={'center'}
                        alignItems='center'
                        spacing={2}
                    >
                        {items.map((item, index) => (
                            <Grid item key={index}>
                                <AdminCard item={item}/>
                            </Grid>
                        ) )}

                    </Grid>
                )}
            </Grid>

            <Grid item paddingTop={5}>
                <Pagination count={amountPages} variant="outlined" color="primary" onChange={(e, page) => paginationOnChange(page)} page={page}/>
            </Grid>
        </Grid>
    </div>
  )
}
