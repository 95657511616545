import {blockchainTypes} from './blockchainTypes';
import { db } from 'config/firebase';

export const loadNFTStats = () => {
    return (dispatch) => {
        try {
            db.collection("drop1").doc("nft").onSnapshot((doc) => {
                const {minted, total, withoutPhysical, withPhysical, date} = doc.data();
                    const stats = {minted, total, withoutPhysical, withPhysical, date}
                    dispatch(setStats(stats));
            });
            
        } catch ( error ){
            // TODO what should we do?
            console.log(error);
        }
    }
}

export const getMyItems = () => {
    return async (dispatch, getState) => {
        try {
            const {address} = getState().walletProvider;
            dispatch(emptyItems())

            if (!address){
                return;
            }
            
            const query = db.collection('drop1').doc('nft').collection('metadata').where('owner', '==', address);
                query.onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const item = change.doc.data();
                    if (change.type === "added") {
                        dispatch(addItem(item))
                    }
                    if (change.type === "modified") {
                        dispatch(updateItem(item))
                    }
                    if (change.type === "removed") {
                        dispatch(removeItem(item))
                    }
                });
            });
        } catch ( error ){
            // we are ignoring for now.
            console.log(error);
        }
    }
}

const setStats = (stats) => ({
    type: blockchainTypes.loadStats,
    payload: stats
})

const addItem = (item) => ({
    type: blockchainTypes.ItemAdd,
    payload: item
})

export const updateItem = (item) => ({
    type: blockchainTypes.ItemUpdate,
    payload: item
})

const removeItem = (item) => ({
    type: blockchainTypes.ItemRemove,
    payload: item
})

export const emptyItems = () => ({
    type: blockchainTypes.ItemEmpty
})

export const submitAddress = async (wallet, address) => {
    return db.collection('users').doc(wallet).update(address);
}

export const loadAddress = async (walletAddress) => {
    const snapshot = await  db.collection('users').doc(walletAddress).get();
    if (snapshot.exists){
        const address = snapshot.data();
        return address;
    } else
        return null;
}

export const setWithPhysical = async (address) => {
    await db.collection('users').doc(address).update({withPhysical: true});
}

export const isWithPhysical = async (address) => {
    const snapshot = await  db.collection('users').doc(address).get();
    if (snapshot.exists){
        const data = snapshot.data();
        return (data.withPhysical);
    } else
        return false;
}