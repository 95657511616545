export const uiTypes = {
    uiCloseAlert: '[UI] - Hides alert message',
    uiOpenAlert: '[UI] - Shows the alert message',

    uiOpenBackdrop: '[UI] - opens the backdrop',
    uiCloseBackdrop: '[UI] - close the backdrop',

    uiSetView: '[UI] specifies which view to show',

    uiSetAdminActiveLink: '[UI] sets the active link on the admin section',

    uiOpenFundWallet: '[UI] - Opens the fund wallet dialog',
    uiCloseFundWallet: '[UI] - closes the fund wallet dialog',
}