import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Container from 'common/Container';
import { isMobile } from 'web3modal';
import { ParallaxProvider } from 'react-scroll-parallax';

import { About } from './About';
import Hero from './Hero'


import './Home.css';
import { Interactive } from './Interactive';
import { MintPhases } from './MintPhases';
import { FAQ } from './FAQ';
import { Team } from './Team';
import { Social } from './Social';
import { Preview } from './Preview';
import { Grid } from '@mui/material';
import { MintPhase } from './MintPhase';


import {auth} from 'config/firebase';
import { connectWalletProviderCache } from 'reducers/walletProviderReducer/walletProviderActions';
import { loadUniverse } from 'reducers/universeReducer/universeActions';
import { getMyItems } from 'reducers/blockchainReducer/blockchainActions';
import { authStartSignUpUser } from 'reducers/authReducer/authActions';
import { DailyMint } from 'views/DailyMint';

const mintPhase = process.env.REACT_APP_MINT_PHASE || '0'

export const Home = () => {
  const {address} = useSelector(state => state.walletProvider);

  const dispatch = useDispatch();
  useEffect(() => {
    // if we have a provider in the cache, we use it to connect to
    dispatch(connectWalletProviderCache());
  }, []);

  useEffect(() =>{
    if (address) {
      // we load the universe
      dispatch(loadUniverse());
      // we load my items
      dispatch(getMyItems());
      
      auth.onAuthStateChanged(async (user) =>{
        if(user?.uid && user.uid === address){
            dispatch(authStartSignUpUser(user.uid));
        } 
    })
}
},[address])
  
  return (
    <ParallaxProvider>
      <div className='space'> 
        <Grid 
          container
          direction="column"
        >
          {(mintPhase === '0') && (
            <Grid item>
              <Container paddingY={0}>
                <Hero/>
              </Container>
            </Grid>
          )}

        {(mintPhase == '1') && (
            <Grid item>
              <Container paddingY={0}>
                <MintPhase/>
              </Container>
            </Grid>
          )}

        {(mintPhase == '3') && (
            <Grid item>
              <Container paddingY={0}>
                <DailyMint/>
              </Container>
            </Grid>
          )}

          <Grid item>
            <About/>
          </Grid>

          <Grid item marginTop={(isMobile) ? -1 : -25} zIndex={3}>
            <Container>
                <Interactive/>
            </Container>
          </Grid>

          {(process.env.REACT_APP_MINT_PHASE != '3') & (
            <Grid item>
              <Preview/>
            </Grid>
          ) }

          <Grid item>
            <Container marginTop={(isMobile) ? -1 : -8}>
              <MintPhases/>
            </Container>
          </Grid>

          <Grid item>
            {/* <Container>
              <Collaborative/>
            </Container> */}
          </Grid>

          <Grid item>
            <Container>
              <FAQ/>
            </Container>
          </Grid>


            <Grid item>
              <Container>
                <Team/>
              </Container>
            </Grid>

            <Grid item>
              <Container>
                <Social/>
              </Container>
            </Grid>
        </Grid>

      </div>
    </ParallaxProvider>
  )
}
