import {uiTypes} from './uiTypes';

export const uiCloseAlert = () => ({
    type: uiTypes.uiCloseAlert
})

export const uiShowAlert = (severity, title, message) => ({
    type: uiTypes.uiOpenAlert,
    payload: {severity, title, message}
})

export const uiShowBackdrop = () => ({
    type: uiTypes.uiOpenBackdrop
})

export const uiCloseBackdrop = () => ({
    type: uiTypes.uiCloseBackdrop
})

export const uiSetView = (view) => ({
    type: uiTypes.uiSetView,
    payload: view
})

export const uiSetAdminActiveLink = (id) => ({
    type: uiTypes.uiSetAdminActiveLink,
    payload: id
})

export const uiOpenFundWalletDialog = () => ({
    type: uiTypes.uiOpenFundWallet
})

export const uiCloseFundWalletDialog = () => ({
    type: uiTypes.uiCloseFundWallet
})