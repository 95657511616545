import React from 'react'
import { Card, CardContent, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { BigNumber } from 'ethers'

import { toETH } from 'utils/blockchain'
import {setDailyMintOption} from 'reducers/mintReducer/mintActions';

export const DailyOption = () => {
    const {dailyMintOption, dailyMintItem} = useSelector(state => state.mint);
    const dispatch = useDispatch()

    const theme = useTheme();
    const {stats} = useSelector(state => state.blockchain);
    const {withoutPhysical, withPhysical} = stats;

    const getPrice = (option) => {
        if (option === 1){
            return BigNumber.from(withoutPhysical).mul(1)
        } else
            return BigNumber.from(withPhysical).mul(1)
    }

    const getPriceETH = (option) => {
        const price = getPrice(option);
        return toETH(price.toString());
    }

    const handleOptionSelection = (option) => {
        dispatch(setDailyMintOption (option) )
    }
  return (
    <Grid
        container
        direction='column'
        justifyContent={'center'}
        alignItems='center'
        spacing={2}
    >
        <Grid item>
            <Typography
                fontFamily={theme.palette.font.headline_regular}
                variant='h6'
                textTransform={'uppercase'}
                textAlign={'center'}
            >
                Choose one
            </Typography>

            <Typography
                fontFamily={theme.palette.font.headline_content}
                variant='h5'
                textTransform={'uppercase'}
                textAlign={'center'}
                fontWeight={700}
            >
                of the two options
            </Typography>
        </Grid>

        <Grid item>
            <Grid container direction='row' alignItems='center' justifyContent='center'>
                <Grid item xs={12} md={6}>
                    <Card 
                        elevation={2} 
                        style={{border:(dailyMintOption === 1) ? '3px solid white' : '2px solid #5f5f5f' , height: '250px', cursor:'pointer'}} 
                        onClick={() => handleOptionSelection(1)}
                    >
                        <CardContent>
                            <Grid 
                                container 
                                direction='column'
                                justifyContent="center"
                                alignItems="center"
                            >
                                
                                    {(dailyMintItem?.dailyVideo) ? (
                                        <Grid item textAlign={'center'} border={'1px solid grey'} height={'92px'}>
                                            <video id='art1' autoPlay loop muted height={'90px'}>
                                                <source src={dailyMintItem.dailyVideo} type='video/mp4'/>
                                            </video>
                                        </Grid>
                                    ) : (
                                        <CircularProgress/>
                                    )}

                                <Grid item paddingTop={5}>
                                    <Typography 
                                        fontFamily={theme.palette.font.content}
                                        variant='h6'
                                        fontWeight={700}
                                        textAlign={'center'}
                                        textTransform='uppercase'
                                    >
                                        1 stellaluna NFT / {(withoutPhysical) ? getPriceETH(1) : '0'} ETH
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} >
                    <Card elevation={2} 
                        width='100%' 
                        style={{border:(dailyMintOption === 2) ? '3px solid white' : '2px solid #5f5f5f', height: '250px', cursor:'pointer'}}
                        onClick={() => handleOptionSelection(2)}
                    >
                        <CardContent>
                            <Grid 
                                container 
                                direction='column'
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item >
                                    {(dailyMintItem) ? (
                                        <Grid container direction='row' alignItems={'center'} justifyContent='center'  >
                                            <Grid item xs={5} border={'1px solid grey'} height={'92px'}>
                                                <video id='art2' autoPlay loop muted height={'90px'} >
                                                    <source src={dailyMintItem?.dailyVideo} type='video/mp4'/>
                                                </video>
                                            </Grid>
                                            <Grid item xs={7} border={'1px solid grey'} height={'92px'}>
                                                <img src={dailyMintItem?.dailyPhysical} alt='phase2' height={'90px'} width='100%'/>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <CircularProgress/>
                                    )}
                                </Grid>

                                <Grid item paddingTop={3}>
                                    <Typography 
                                        fontFamily={theme.palette.font.content}
                                        variant='h6'
                                        fontWeight={700}
                                        textAlign={'center'}
                                        textTransform='uppercase'
                                    >
                                        1 stellaluna NFT + 1 signed <br/> physical print / {(withPhysical) ? getPriceETH(2) : '0'} ETH
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>

    </Grid>
  )
}
