import {universeTypes} from './universeTypes';
import { db } from 'config/firebase';

export const addUniverseItem = (item) => ({
    type: universeTypes.universeAddItem,
    payload: item
})

export const updateUniverseItem = (item) => ({
    type: universeTypes.universeUpdateItem,
    payload: item
})

export const removeUniverseItem = (item) => ({
    type: universeTypes.universeRemoveItem,
    payload: item
})

export const selectedUniverseItem = (item) => ({
    type: universeTypes.universeSelectItem,
    payload: item
})

export const saveUniverse = async (address, universe) => {
    await db.collection('myUniverse').doc(address).set(universe);
}

export const loadUniverse = () => {
    return async (dispatch, getState) => {
        const {address} = getState().walletProvider;
        try {
            dispatch(resetUniverse());

            const snapshot = await db.collection('myUniverse').doc(address).get();
            if (snapshot.exists){
                const {items} = snapshot.data();
                for (const item of items){
                    dispatch(addUniverseItem(item))
                }
            }
        } catch ( error ) {
            console.log(error);
            // TODO what should we do?
        }
    }
}

export const resetUniverse = () => ({
    type: universeTypes.universeReset
})

export const setNeedSave = (isNeeded) => ({
    type: universeTypes.universeSetNeedSave,
    payload: isNeeded
})

export const makeUniversePublic = async (address, universe) => {
    await db.collection('universes').doc(address).set(universe);
}

export const getPublicUniverses = async () => {
    const snapshot = await db.collection('universes').get();
    const result = [];
    if (snapshot.empty)
        return result;

    for (const doc of snapshot.docs){
        const item = doc.data();
        result.push(item);
    }

    return result
}

export const setUniverseFavourite = async (universeOwner, address) => {
    const ref = await db.collection('universes').doc(universeOwner);
    const snapshot = await ref.get();
    const universe = snapshot.data();
    if (!universe.favourites){
        // first favourite for this universe
        await ref.update({favourites: [address]})
    } else {
        if (universe.favourites.find(val => val === address)){
            // this address already voted, we remove the vote
            await ref.update({favourites: [...universe.favourites.filter(val => val !== address)]})
        } else {
            // new favourite
            await ref.update({favourites: [...universe.favourites, address]})
        }
    }

} 
