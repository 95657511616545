import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material'

export const FAQ = () => {
    const theme = useTheme();

    const faqs = [
        {q: 'what are stellaluna NFTS?', a: 'STELLALUNA is an immersive experience and the first of its kind. You have the extraordinary opportunity to explore the art with an interactive 360° experience of what it would be like to travel in time to outer space. STELLALUNA, created by Nois7, is his first generative project.'},
        {q: 'HOW DO I GET A STELLALUNA NFT?', a: 'You can mint your STELLALUNA NFT on Phase 1 or Phase 2 as shown in the MINT PHASES section above'},
        {q: 'DOES STELLALUNA NFTS HAVE UTILITY?', a: 'Yes, there are amazing things planned for all holders.'},
        {q: 'HOW WILL THE SNAPSHOT WORK?', a: 'On a specific date (mentioned above) we will take a snapshot of your wallet to track which Nois7 NFTs you own. This will prevent people from minting on several phases with the same NFT.'},
        {q: 'WHAT NOIS7 NFTS ARE INCLUDED IN PHASE 1?', a: 
        <span>
        <b>PHASE 1:</b><br/>
        Black Hole<br/>
        White Hole<br/>
        Space Explosion - 1/1 Pink Edition<br/>
        Space Explosion - 1/1 Red Edition<br/>
        Space Explosion - 1/1 Blue Edition<br/>
        When Hope Arises 1/1<br/>
        The Sunrise 1/1<br/>
        The Lightning 1/1<br/>
        The Milky Way 1/1<br/>
        The Aurora Borealis 1/1<br/>
        The Sunset 1/1<br/>
        The Land Of Wonders 1/1<br/>
        Sun Eclipse 1/1<br/>
        Moon Eclipse 1/1<br/>
        Reunion Of The Cats<br/>
        Sparkling Dust Auctions<br/>
        Mystic Deer Auctions<br/>
        Bird Whisperer Auctions<br/>
        Dreamcatcher Auctions<br/>
        San Francisco Dreaming Auctions<br/>
        The Cat In Tokyo Auctions<br/>
        Fishing For The Moon Auctions<br/>
        Blood Moon Mystery Auctions<br/>
        Talking To A Bird Auctions<br/>
        The Cat And The Moon Auctions<br/>
        The Sleepwalker Auctions<br/>
        Aurora Dreaming Auctions<br/>
        Reaching To The Moon Auctions<br/>
        Solar Eclipse Auctions<br/>
        All other Nois7 NFTs.<br/>
        </span>},
        {q: 'WILL I BE OK WITH MY LEDGER OR TREZOR FOR MINTING?', a: 'Yes both Ledger and Trezor will work for minting.'},
        {q: 'DO WE HAVE COMMERCIAL RIGHTS TO OUR STELLALUNAS?', a: 'As of now we offer IP rights for the STELLALUNA soundtrack composed by Nois7.'},
        {q: 'WHERE IS THE NFT METADATA STORED?', a: 'The NFT Metadata is stored on IPFS.'},
        {q: 'WHAT ARE THE ROYALTIES ON SECONDARY SALES?', a: '10%'},
    ]
  return (
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
    >
        <Grid item>
            <Typography
                gutterBottom
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.2em'
                variant='h4'
                textTransform={'uppercase'}
                textAlign={'center'}
            >
                FAQ
            </Typography>
        </Grid>

        {faqs.map((item, index) => (
            <Grid item key={index}>
                <Accordion elevation={0}>
                    <AccordionSummary
                        // expandIcon={<AddIcon sx={{color: 'white'}} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        // sx={{flexDirection: 'row-reverse'}}
                           sx={{flexDirection: 'column'}}
                    >
                        <Typography
                            
                            fontFamily={theme.palette.font.content}
                            variant='body1'
                            fontWeight={700}
                            textAlign='center'
                            textTransform={'uppercase'}
                        >
                            <AddIcon sx={{color: 'white', verticalAlign: 'middle',display: 'inline-flex' }} /> {` `}{item.q}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body1'
                            textAlign={'center'}
                        >
                            {item.a}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>

        ))}
    </Grid>
  )
}
