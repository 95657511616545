import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material'

export const Team = () => {
    const theme = useTheme();
  return (
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
    >
        <Grid item>
            <Typography
                gutterBottom
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.1em'
                variant='h4'
                textTransform={'uppercase'}
                textAlign={'center'}
            >
                Team
            </Typography>
        </Grid>

        <Grid item>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='body1'
                textTransform={'uppercase'}
                fontWeight={700}
                display="inline"
                textAlign={'center'}
            >
                Nois7
            </Typography>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='body1'
                display="inline"
                textTransform={'uppercase'}
                textAlign={'center'}
            >
                {` `} / Artist & founder
            </Typography>
        </Grid>

        <Grid item>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='body1'
                textTransform={'uppercase'}
                fontWeight={700}
                textAlign={'center'}
                display="inline"
            >
                Jupitergroup.io
            </Typography>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='body1'
                textTransform={'uppercase'}
                textAlign={'center'}
                display="inline"
            >
                {` `} / co-founder
            </Typography>
        </Grid>

        
    </Grid>
  )
}
