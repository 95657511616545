import React from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { isMobile } from 'react-device-detect'

import print from 'images/mintPhases/print.png'
import { useSelector } from 'react-redux'

export const DailyPhysical = () => {
    const theme = useTheme();
    const {dailyMintItem} = useSelector(state => state.mint);
  return (
    <Grid 
        container 
        direction='column' 
        justifyContent="center"
        alignItems="center"
        paddingTop={7}
        spacing={3}
    >
        <Grid item>
            <Typography align='center' variant='h4' gutterBottom textTransform={'uppercase'} fontFamily={theme.palette.font.headline_regular} letterSpacing='0.2em'>
                About the physical prints
            </Typography>
        </Grid>

        <Grid item marginLeft={(isMobile) ? 1 : 10}>
            <img src={(dailyMintItem) ? dailyMintItem.dailyPhysical : print} alt='physicalPrint' width={'100%'} />
        </Grid>

        <Grid item>
            <Typography variant='body' gutterBottom textAlign={'center'} align='center'>
                The limited edition print collection has its own rarity.
            </Typography>
        </Grid>
        <Typography variant='body' gutterBottom textAlign={'center'} align='center'>
            Only for the EVERYDAYS the Nebula Print will be 1/1's and the same as your NFT.
        </Typography>

        <Grid item paddingBottom={2}>
            <Box textAlign={'center'}>
                <Typography fontFamily={theme.palette.font.content} variant='body1' display={'inline'} fontWeight={700} align='center'>
                    Available Nebula Artworks:&nbsp;
                </Typography>
                <Typography fontFamily={theme.palette.font.content} variant='body1' display={'inline'} align='center'>
                    TBA<br/>
                </Typography>
            </Box>

            <Box textAlign={'center'}>
                <Typography fontFamily={theme.palette.font.content} variant='body1' display={'inline'} fontWeight={700} align='center'>
                    Edition Size:&nbsp;
                </Typography>
                <Typography variant='body' gutterBottom display='inline' align='center'>
                    1/1<br/>
                </Typography>
            </Box>

            <Box textAlign={'center'}>
                <Typography fontFamily={theme.palette.font.content} variant='body1' display={'inline'} fontWeight={700} align='center'>
                    Format:&nbsp;
                </Typography>
                <Typography variant='body' gutterBottom display='inline' align='center'>
                    100x50 cm<br/>
                </Typography>
            </Box>

            <Box textAlign={'center'}>
                <Typography fontFamily={theme.palette.font.content} variant='body1' display={'inline'} fontWeight={700}>
                    Material:&nbsp;
                </Typography>
                <Typography variant='body' gutterBottom display='inline'>
                    Foarex Print<br/>
                </Typography>
            </Box>

            <Box textAlign={'center'}>
                <Typography fontFamily={theme.palette.font.content} variant='body1' display={'inline'} fontWeight={700}>
                    Frame:&nbsp;
                </Typography>
                <Typography variant='body' gutterBottom display='inline'>
                    Black Wood<br/>
                </Typography>
            </Box>

            <Box textAlign={'center'}>
                <Typography fontFamily={theme.palette.font.content} variant='body1' display={'inline'} fontWeight={700}>
                    Signed:&nbsp;
                </Typography>
                <Typography variant='body' gutterBottom display='inline'>
                    Artist Signed<br/>
                </Typography>
            </Box>
        </Grid>
    </Grid>
  )
}
