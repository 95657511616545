import React, {useEffect} from 'react'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import Countdown, {zeroPad} from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {setDailyMintCountDownZero} from 'reducers/mintReducer/mintActions';

export const DailyCountdown = () => {
    const {dailyMintItem} = useSelector(state => state.mint);
    const theme = useTheme();
    const dispatch = useDispatch()

    const startDate = process.env.REACT_APP_DAILY_MINT_START_DATE;
    const date = moment.unix(startDate);
    const now = moment();
    const diff = now.diff(date, 'days')
    const countdown = date.add(diff + 1, 'days').toDate();

    useEffect(() => {
      if (dailyMintItem && !dailyMintItem.minted)
        dispatch(setDailyMintCountDownZero(true))
    }, [dailyMintItem])
    

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          dispatch(setDailyMintCountDownZero(true))
          // Render a completed state
          return;
        } else {
          // Render a countdown
          return (
            <Typography
                fontFamily={theme.palette.font.headline_content}
                variant='h2'
                textTransform={'uppercase'}
                fontWeight={700}
                textAlign={'center'}
            >
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </Typography>
          );
        }
      };
  return (
    <Grid
        container
        direction='column'
        justifyContent={'center'}
        alignItems='center'
        spacing={2}
    >
        <Grid item>
            <Typography
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.2em'
                variant='h6'
                textTransform={'uppercase'}
                textAlign={'center'}
            >
                Countdown to next sale
            </Typography>
        </Grid>

        <Grid item>
          {(dailyMintItem && !dailyMintItem.minted) ? (
            <Typography
              fontFamily={theme.palette.font.headline_content}
              variant='h5'
              textTransform={'uppercase'}
              fontWeight={700}
              textAlign={'center'}
            >
              Ready to mint!
            </Typography>
          ) : (
            <Countdown 
              date={countdown } 
              renderer={renderer} 
              zeroPadTime={2}
            />
          )}
        </Grid>
    </Grid>
  )
}
