import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Grid, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { StellaLunaDialog } from 'views/Dashboard/components/StellaLunaDialog';
export const DailyMetadata = props => {
    const {item} = props;
    const [open, setOpen] = useState(false)
  return (
    <Grid
        container
        direction='column'
        justifyContent={'center'}
        alignItems='flex-start'
        spacing={2}
    >
        <Grid item>
            <Button sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} onClick={() => setOpen(true)}>
                <Typography textTransform={'uppercase'} variant='body1'>
                    Experience todays NFT
                </Typography>
            </Button>
        </Grid>

        <Grid item>
            <Box display='flex' alignItems='center'>
                <InfoIcon/>
                <Typography
                    variant='body1'
                    display='inline'
                    textTransform={'uppercase'}
                    fontWeight={700}
                >
                      &nbsp; Todays nft traits
                </Typography>
            </Box>
        </Grid>

        <Grid item >
            <Typography  fontWeight={700} display='inline' color={'#00ffc6'}>
                Special: &nbsp;
            </Typography>
            <Typography display='inline' color={'#00ffc6'}>
                Artist Selected
            </Typography>
            {(item.attributes.filter(val => val.trait_type !== 'artistSelected').map((val, index) => (
                <div key={index}>
                    <Typography  fontWeight={700} display='inline'>
                        {val.trait_type}: &nbsp;
                    </Typography>
                    <Typography display='inline'>
                        {(val.value === 'star1') ? 'Star1' : val.value} 
                    </Typography>
                </div>
            )))}
        </Grid>
        <StellaLunaDialog item={item} open={open} setOpen={setOpen}/>
    </Grid>
  )
}

DailyMetadata.propTypes = {
    item: PropTypes.object.isRequired
}
