import React, {useEffect, useState} from 'react'
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import { AdminCard } from '../AdminCard';
import { getNext5NFT } from 'reducers/adminReducer/adminActions';

export const NFTNext5 = () => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const theme = useTheme()

    const dispatch = useDispatch();
    
    const loadNect5NFT = async () => {
        try {
            setLoading(true)
            const result = await getNext5NFT();
            setItems(result)
        } catch (error){
            console.log(error);
            dispatch(uiShowAlert('warning', 'Error', 'We have been unable to load the next 5 NFTs. Refresh and retry later.'))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadNect5NFT();
    }, [])
    
  return (
    <div>
        <Box textAlign={'center'} paddingBottom={5}>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='h5'
                align='center'
            >
                Review next 5 NFTs to be minted
            </Typography>
        </Box>

        {(loading) ? (
            <div className='center-screen'>
                <CircularProgress/>
            </div>
        ) : (
                <Grid
                    container
                    direction={'row'}
                    justifyContent='center'
                    alignItems={'center'}
                    spacing={2}
                >
                {items.map((item, index) => (
                    <Grid item key={index}>
                        <AdminCard item={item}/>
                    </Grid>
                ))}
                </Grid>
        )}

    </div>
  )
}
