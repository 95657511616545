import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Grid, Link, Typography } from '@mui/material'
import { Box, useTheme } from '@mui/system'
import Container from 'common/Container'
import ReactPlayer from 'react-player'

import { ethers, utils } from 'ethers'
import video from 'images/accessPass/AccessPassNFT.mov'
import background from 'images/accessPass/background.jpg'
import { shortenAddress } from 'utils/blockchain'
import { FAQ } from 'views/Home/FAQ'
import { Team } from 'views/Home/Team'
import { Social } from 'views/Home/Social'
import { showWalletProviderModal } from 'reducers/walletProviderReducer/walletProviderActions'
import { uiShowAlert } from 'reducers/uiReducer/uiActions'
import abi from 'utils/StellaLunaAccessPass.json';
import { MintingDialog } from 'views/Home/MintPhase/components/Common/MintingDialog'

export const AccessPassMint = () => {
    const showWallet = (process.env.REACT_APP_SHOW_CONNECT_WALLET === 'false' ? false : true);
    const theme = useTheme()
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true)
    const [open, setOpen] = useState(false);
    const {address, provider} = useSelector(state => state.walletProvider);

    const handleWalletConnectClick = () => {
        if (!address)
            dispatch(showWalletProviderModal());
    }

    const handleMint = async () => {
        try {
            const signer = provider.getSigner();
            const accessPassNFT = new ethers.Contract(process.env.REACT_APP_STELLALUNA_ACCESSPASS_ADDRESS, abi.abi, signer);
            const tx = await accessPassNFT.mintAccessPass();
            setOpen(true);
            await tx.wait();
            dispatch(uiShowAlert('success', 'Access Pass', 'Congratulations! Access pass minted.'))
        } catch ( error ){
            console.log(error);
            dispatch(uiShowAlert('error', 'Access Pass', 'There was an error minting your access pass. Please refresh and try again.'))
        } finally {
            setOpen(false);
        }
    }

    const checkIsAllowed = async () => {
        try {
            const result = await fetch(
                'https://stellaluna-proxy.herokuapp.com/https://api.premint.xyz/v1/1ec2766444f31c5e81681379576f69678759b35a4cf159537e9f96580b434ef1/', 
                {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                  }
            );
            const response = await result.json();
            const accessList = response.data;
            console.log(accessList);
            if (accessList.find(value => utils.getAddress(value.wallet) === address)){
                setIsDisabled(false)
                dispatch(uiShowAlert('success', 'Ready to mint?', 'Congratulations! You are part of the Access Pass list.'));
            }
            else{
                setIsDisabled(true)
                dispatch(uiShowAlert('warning', 'No access', 'We are sorry but you are not part of the Access Pass list.'));
            }
                
        } catch ( error ) {
            console.log(error);
            dispatch(uiShowAlert('error', 'Allow List', 'We have been unable to verify if you are in the Allow list. Refresh and try again please.'))
        }
    }

    useEffect(() => {
        if (!address){
            setIsDisabled(true);
        } else {
            checkIsAllowed()
        }
    }, [address])
    
  return (
    <div className='space'>
        <Box position={'relative'} >
            <img
                src={background}
                width="100%"
                style={{position:'absolute', zIndex: 0, left: '50%', transform: 'translateX(-50%)'}}
            />
        </Box>
        <Container>
            {(showWallet) && (
                <Button 
                variant='outlined'
                sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}
                onClick={handleWalletConnectClick}
                >
                <Typography fontFamily={theme.palette.font.content} variant='button'>
                    {(address) ? shortenAddress(address) : 'CONNECT WALLET'}
                </Typography>
                </Button>
            )}


            <Grid 
                container
                direction='column'
                justifyContent={'center'}
                alignItems='center'
                spacing={2}
            >
                <Grid item zIndex={2} marginTop={4}>
                    <Typography
                        fontFamily={theme.palette.font.headline_light}
                        variant='h6'
                        textAlign={'center'}
                        letterSpacing='0.2em'
                        gutterBottom
                        textTransform={'uppercase'}
                    >
                        Mint your free
                    </Typography>
                    <Typography
                        fontFamily={theme.palette.font.headline_regular}
                        letterSpacing='0.3em'
                        variant='h4'
                        textAlign={'center'}
                        gutterBottom
                        textTransform={'uppercase'}
                    >
                        Stelalluna
                    </Typography>
                    <Typography
                        fontFamily={theme.palette.font.headline_light}
                        variant='h6'
                        letterSpacing='0.2em'
                        textAlign={'center'}
                        gutterBottom
                        textTransform={'uppercase'}
                    >
                        Access pass
                    </Typography>
                </Grid>

                <Grid item textAlign={'center'} zIndex={2}>
                    <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            textAlign={'center'}
                            fontWeight={700}
                            gutterBottom
                            textTransform={'uppercase'}
                    >
                        0 / 500<br/>
                        Minted
                    </Typography>
                </Grid>

                <Grid item zIndex={2}>
                    <ReactPlayer
                        url={video}
                        playing
                        loop
                        muted
                        width='250px'
                        height={'140px'}
                        style={{border: '1px solid white'}}
                    />
                </Grid>

                <Grid item textAlign={'center'} zIndex={2}>
                    <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            textAlign={'center'}
                            fontWeight={700}
                            gutterBottom
                            textTransform={'uppercase'}
                    >
                        1 free access pass / 0.00 ETH<br/>
                    </Typography>
                    Just pay gas
                </Grid>

                <Grid item zIndex={2}>
                    <Button 
                        variant='outlined'
                        disabled={isDisabled}
                        sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}
                        onClick={handleMint}
                    >
                        <Typography fontFamily={theme.palette.font.content} fontWeight={700} textTransform='uppercase' variant='button'>
                            Mint now 
                        </Typography>
                    </Button>
                </Grid>

                <Grid item textAlign={'center'} zIndex={2}>
                    Max 1 per transaction.<br/>
                    No Max per wallet.
                </Grid>

                <Grid item marginTop={4} textAlign='center' zIndex={2}>
                    <Typography
                        fontFamily={theme.palette.font.headline_light}
                        variant='h5'
                        letterSpacing='0.2em'
                        textAlign={'center'}
                        gutterBottom
                        textTransform={'uppercase'}
                    >
                        The access pass
                    </Typography>
                    By holding the STELLALUNA ACCESS PASS NFT it gives you the following exclusive benefits:<br/><br/>

                    - Access to the STELLALUNA WL Sale<br/>
                    - Chance to win 1 of 3 STELLALUNA NFTs<br/>
                    - Chance to win 1 Artist Signed Physical Print<br/>
                    - Access to future exclusive selected drops<br/>
                    - Claim Free Merch<br/>
                    - Early Access to the VR Experience<br/><br/>

                    When you hold this ACCESS PASS on the TBA date, you are eligible to register for WL on the following premint.xyz page: <Link href='http://www.premint.xyz/stellaluna-nft-wl' target={'_blank'} rel="noreferrer">www.premint.xyz/stellaluna-nft-wl</Link><br/>

                    On the full teaser premier with Spatial the ACCESS PASS NFT will also reveal.
                    
                </Grid>

                <Grid item marginTop={2} textAlign='center' zIndex={2}>
                    <Typography
                        fontFamily={theme.palette.font.content}
                        variant='body'
                        textAlign={'center'}
                        gutterBottom
                        fontWeight={700}
                    >
                        Requirements to mint STELLALUNA NFT on WL PHASE:<br/>
                    </Typography>
                    Follow STELLALUNA on Discord<br/>
                    Follow STELLALUNA on Twitter<br/>
                    Hold at least 0.3 ETH in your wallet<br/>
                </Grid>


                <Grid item marginTop={4} zIndex={2}>
                    <FAQ/>
                </Grid>

                <Grid item marginTop={4} zIndex={2}>
                    <Team/>
                </Grid>

                <Grid item marginTop={4} zIndex={2}>
                    <Social/>
                </Grid>
            </Grid>
        </Container>
        <MintingDialog open={open} setOpen={setOpen}/>
    </div>
  )
}
