import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect';
import { Box, useTheme, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import { StellaLunaDialog } from '../StellaLunaDialog';
import { getBase64FromUrl } from 'utils/base64';

export const StellaLunaCard = props => {
  const {item} = props;
  const [image, setImage] = useState(item.static);
  const [open, setOpen] = useState(false)
  const theme = useTheme();
  const {attributes} = (item.attributes) ? item : item.metadata;

  const loadGif = async () => {
    const base64 = await getBase64FromUrl(item.gif)
    setImage(base64)
  }
  useEffect(() => {
    loadGif();
  }, [])
  
  return (
    <div>
      <Card elevation={2} style={{width: (isMobile) ? '100%' : '400px'}}>
        <CardActionArea onClick={() => setOpen(true)}>
          <CardMedia >
            <img src={image} alt={item.tokenId} width='400px' loading='lazy'/>
          </CardMedia>

          <CardContent>
            <Typography
              fontFamily={theme.palette.font.content}
              variant='h6'
              fontWeight={700}
              textAlign={'center'}
              textTransform='uppercase'
            >
              {(item.tokenId) ? `Token Id ${item.tokenId}` : `Art Id ${item.artId}`}
            </Typography>

            {attributes.map((val, index) => (
              <Box display='flex' justifyContent={'center'} key={index}>
                <Typography 
                  fontFamily={theme.palette.font.content}
                  display='inline'
                  fontWeight={700}
                  variant='body1'
                  align='center'
                >
                  {val.trait_type}:&nbsp;
                </Typography>

                <Typography 
                fontFamily={theme.palette.font.content}
                display='inline'
                variant='body1'
                align='center'
              >
                {val.value}
              </Typography>
            </Box>
            ))}
          </CardContent>

        </CardActionArea>
      </Card>
      <StellaLunaDialog item={item} open={open} setOpen={setOpen}/>
    </div>
  )
}

StellaLunaCard.propTypes = {
  item: PropTypes.object.isRequired
}
