import {uiTypes} from './uiTypes';

const initialState = {
    alert: {
        open: false,
        severity: 'success',
        message: '',
        title: ''
    },
    backdrop: false,
    view: 'home',
    adminActiveLink: 'artReview',
    openFundWallet: false,
};


export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        
        // shows the alert message
        case uiTypes.uiOpenAlert:
            return ({
                ...state,
                alert: {open: true, severity: action.payload.severity, title: action.payload.title, message: action.payload.message}
            })

        // closes the alert message
        case uiTypes.uiCloseAlert:
            return ({
                ...state,
                alert: {...state.alert, open: !state.alert.open}
            })

        case uiTypes.uiOpenBackdrop:
            return {
                ...state,
                backdrop: true
            }

        case uiTypes.uiCloseBackdrop:
            return {
                ...state,
                backdrop: false
            }

        case uiTypes.uiSetView:
            return{
                ...state,
                view: action.payload
            }
        
        case uiTypes.uiSetAdminActiveLink:
            return {
                ...state,
                adminActiveLink: action.payload
            }
        
        case uiTypes.uiOpenFundWallet:
            return{
                ...state,
                openFundWallet: true
            }

        case uiTypes.uiCloseFundWallet:
            return{
                ...state,
                openFundWallet: false
            }
        default:
            return state;
    }
}