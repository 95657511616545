import React, {useEffect, useRef, useState} from 'react'

import PropTypes from 'prop-types'
import * as THREE from 'three';
import useSound from 'use-sound';

import useWindowDimensions from 'hooks/useWindowDimensions';
import {OrbitControls} from './OrbitControls';
import cloudImg from 'images/art/cloud.jpg';


import './LunaAnimation.css'
import { storage } from 'config/firebase';

export const LunaAnimation = (props) => {
  const {nebula, sound, generateGif = false, color = '#4a2a6c', artId} = props;
  const [muted, setMuted] = useState(false);
  const [playSound, {isPlaying, stop}] = useSound(sound, {loop: true});
  const frame = useRef(0)
  const maxFrames = 2;
  
  const canvasRef = useRef();
  const canvas = canvasRef.current;

  const scene = new THREE.Scene();

  const loader =  new THREE.TextureLoader();

  // SPHERE MAIN
  const geometry = new THREE.SphereBufferGeometry(1, 64, 64);
  const mainSphere = loader.load(nebula);
  const material = new THREE.MeshPhongMaterial({
    map: mainSphere,
    side: THREE.DoubleSide,
    shininess: 0,
    opacity: 0.85,
    alphaTest: 0.85,
    transparent: true,
  });
  material.transparent = true


  const sphere = new THREE.Mesh(geometry, material);
  sphere.renderOrder = 0
  scene.add(sphere);


  // // CLOUDS IN SPHERE
  const geometry4 = new THREE.SphereBufferGeometry(0.8, 64, 64);
  const clouds = loader.load(cloudImg)
  const material4 = new THREE.MeshPhongMaterial({
    map: clouds,
    side: THREE.DoubleSide,
    color: color,
    opacity: 0.6,
    shininess: 0,
    transparent: true,
    blending: THREE.AdditiveBlending,
  });
  material4.transparent = true

  const inside = new THREE.Mesh(geometry4, material4);
  inside.renderOrder = 1
  scene.add(inside);



  // LIGHT
  const sunLight = new THREE.DirectionalLight(new THREE.Color(0xffffff), 0.6);
  sunLight.position.set(300, 100, 400);
  var lightHolder = new THREE.Group();
  lightHolder.add(sunLight);
  scene.add(lightHolder);

  const sunLight2 = new THREE.DirectionalLight(new THREE.Color(0xffffff), 0.6);
  sunLight2.position.set(-200, -200, 400);
  var lightHolder2 = new THREE.Group();
  lightHolder2.add(sunLight2);
  scene.add(lightHolder2);

  lightHolder.shadowMapWidth = 1024; 
  lightHolder.shadowMapHeight = 1024; 
  lightHolder2.shadowMapWidth = 1024;
  lightHolder2.shadowMapHeight = 1024; 


  
  const { height, width } = useWindowDimensions();
  // const height='100px';
  // const width='100px'
  const sizes = {
    width: width,
    height: height
  };

  const camera = new THREE.PerspectiveCamera(
    50,
    sizes.width / sizes.height,
    0.1,
    2000
  );
  camera.position.x = 0;
  camera.position.y = 0;
  camera.position.z = 3;
  scene.add(camera);

  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
    preserveDrawingBuffer: (generateGif) ? true : false
  });

  renderer.setClearColor (0x000000, 0);

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.toneMapping = THREE.LinearToneMapping;
  renderer.toneMappingExposure = 1.2;

  // CONTROLS
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableZoom = true;
  controls.enablePan = false;
  controls.enableDamping = true;
  controls.dampingFactor = 0.03;
  controls.minDistance = 0.4;
  controls.maxDistance = 8;
  controls.zoomSpeed = 0.3;
  controls.keys = {
    LEFT: "ArrowLeft",
    UP: "ArrowUp", 
    RIGHT: "ArrowRight", 
    BOTTOM: "ArrowDown", 
  };

  const clock = new THREE.Clock();

  const requestRef = React.useRef()

  const tick = async () => {
    const deltaTime = clock.getDelta();
    const elapsedTime = clock.getElapsedTime();
    
    lightHolder.quaternion.copy(camera.quaternion);
    lightHolder2.quaternion.copy(camera.quaternion);
    
    sphere.rotation.y = 0.07 * elapsedTime;
    inside.rotation.y = 0.07 * elapsedTime;

    controls.update();
    renderer.render(scene, camera);

    if (generateGif){
      // we are generating the gif. We will store all files in storage and then close it
      if (frame.current < maxFrames){
        renderer.setClearColor (0x000000, 0);
        const strMime = 'image/png';
        const imgData = renderer.domElement.toDataURL(strMime);
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`sphere/${artId}/frame${frame.current}.png`)
        await imageRef.putString(imgData, 'data_url')
        console.log(`Uploaded frame ${frame.current}`);
        frame.current = frame.current + 1;
        requestRef.current = window.requestAnimationFrame(tick);
      } else {
        window.opener = null;
        window.open("about:blank", "_self");
        window.close();
      }
    } else
      requestRef.current = window.requestAnimationFrame(tick);
    
  };
  
  const [startAnimation, setStartAnimation] = useState(false);
  const [startSound, setStartSound] = useState(false);

  useEffect(() => {
    if (!isPlaying){
      playSound();
    }
  }, [startSound, isPlaying])
  
  
  useEffect( () => {
    if (startAnimation){
      
      requestRef.current =  window.requestAnimationFrame(tick);
      return () => window.cancelAnimationFrame(requestRef.current);
    }
  }, [startAnimation])

  useEffect(() => {
    if (muted){
      stop();
    }
    if (!muted){
      playSound();
    }
  }, [muted])
  


  
  useEffect(() => {
    window.addEventListener("resize", () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
    
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
    
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }); 

    window.addEventListener("pointerup", () => {
      setStartSound(true)
    })

    setStartAnimation(true)

    document.addEventListener("keydown", handleKeyDown);
  }, []); // Make sure the effect runs only once


  const handleKeyDown = (e) => {
    // letter M
    if (e.keyCode === 77){
      setMuted(muted => !muted);
    }
  }
  
  return (
    <div>
      {
        <canvas  ref={canvasRef}></canvas>
      }
    </div>
  )
}

LunaAnimation.propTypes = {
  nebula: PropTypes.string.isRequired,
  outside: PropTypes.string.isRequired,
  sound: PropTypes.string.isRequired,
  star: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  generateGif: PropTypes.bool,
  artId: PropTypes.string.isRequired,
}