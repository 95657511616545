import React, {useRef} from 'react'
import { Grid } from '@mui/material'
import { Spheres } from '../Spheres'
import { Controls } from '../Controls'
import { useDispatch, useSelector } from 'react-redux'
import { addUniverseItem, selectedUniverseItem, setNeedSave, updateUniverseItem } from 'reducers/universeReducer/universeActions'
import backgroud from 'images/universe/universe_bg_1500x500.jpg'
import loadingGif from 'images/universe/loading.gif'

export const Lab = () => {
    const {items, selected} = useSelector(state => state.universe);
    const dispatch = useDispatch()
    const draggingItem = useRef(null);
    const svgRef = useRef();
    
    const manualDragSelectedItem = useRef(null);
    const currentX = useRef(0);
    const currentY = useRef(0);

    const overLabCoordinates = useRef({x:0, y:0})

    const handleSelectSphere = (item) => {
        dispatch(selectedUniverseItem(item));
    }

    
    const onSphereDragStart = (item) => {
        // we make sure the item being drag is not being used already
        if (!items.find(universeItem => universeItem.tokenId === item.tokenId)){
            draggingItem.current = item;
            dispatch(selectedUniverseItem(item));
        }
        else
            draggingItem.current = null;
    }
    const onDragOverLab = (event) => {
        const rect = svgRef.current.getBoundingClientRect();
        const x = event.clientX - rect.x;
        const y = event.clientY - rect.y;
        overLabCoordinates.current = {x, y}
        event.preventDefault();
    }

    const dropOnLab = (event) => {
        event.preventDefault();
        // we only added it if it doesn't exists.
        if (draggingItem.current){
            draggingItem.current.position = {x: overLabCoordinates.current.x -80, y: overLabCoordinates.current.y -60};
            draggingItem.current.size = 5;
            draggingItem.current.zIndex = 10;
            dispatch(addUniverseItem(draggingItem.current))
            overLabCoordinates.current = {x: 0, y:0}
            handleSelectSphere(draggingItem.current)
            dispatch(setNeedSave(true))
        } 
    }

    const itemsSort = (a,b) => {
        if (a.zIndex < b.zIndex)
            return -1
        else
            return 1
    }

    const handleMouseDown = (e) => {
        currentX.current = e.clientX;
        currentY.current = e.clientY;
        manualDragSelectedItem.current = e.target
    }
    const handleMouseMove = (e) => {
        if (manualDragSelectedItem.current){
            const dx = parseInt(manualDragSelectedItem.current.getAttribute('x')) + e.clientX - currentX.current;
            const dy = parseInt(manualDragSelectedItem.current.getAttribute('y')) + e.clientY - currentY.current;
            currentX.current = e.clientX;
            currentY.current = e.clientY;

            const draggedItem = items.find(val => val.tokenId === manualDragSelectedItem.current.id);
            dispatch(updateUniverseItem({...draggedItem, position:{x: dx, y: dy}}));
            dispatch(setNeedSave(true))
        }
    }
    const handleMouseUp = (e) => {
        manualDragSelectedItem.current = null
        currentX.current = 0
        currentY.current = 0
    }
  return (
        <Grid
            container
            direction={'column'}
            spacing={2}
        >
            <Grid>
                <svg 
                    xmlns='http://www.w3.org/2000/svg'
                    width='1500px'
                    height='500px'
                    style={{position:'relative', border:1, borderStyle:'solid', borderColor:'#404040'}}
                    onDragOver={onDragOverLab}
                    onDrop={dropOnLab}
                    ref={svgRef}
                >
                    <image href={backgroud}/>

                    {items.sort(itemsSort).map((item, index) => (
                            <image 
                                href={(item.base64) ? item.base64 : loadingGif} 
                                key={index} 
                                style={{
                                    cursor: 'pointer',
                                    filter: (selected && selected.tokenId === item.tokenId) ? 'drop-shadow(0 0 0.50rem crimson)' : 'none',
                                }}
                                x={item.position.x}
                                y={item.position.y}
                                width={(item.base64) ? `${item.size * 30}px` : '70px'}
                                id={item.tokenId}
                                onClick={() => {handleSelectSphere(item)}}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                            />
                    ))}
                </svg>
            </Grid>

            <Grid item>
                <Grid 
                    container 
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={9}>
                        <Spheres onDragStart={onSphereDragStart}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Controls/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
  )
}
