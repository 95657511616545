import React, {useState, useEffect} from 'react'

import { Box, CircularProgress, Grid, IconButton, Link, Typography, useTheme } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Container from 'common/Container'
import { useDispatch, useSelector } from 'react-redux';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getPublicUniverses, setUniverseFavourite } from 'reducers/universeReducer/universeActions';
import loadingGif from 'images/universe/loading.gif'
import { StellaLunaDialog } from 'views/Dashboard/components/StellaLunaDialog';
import { shortenAddress } from 'utils/blockchain';
import { showWalletProviderModal } from 'reducers/walletProviderReducer/walletProviderActions';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import backgroud from 'images/universe/universe_bg_1500x500.jpg'

export const Universes = () => {
    const theme = useTheme();
    const [universes, setUniverses] = useState([]);
    const [open, setOpen] = useState(false)
    const [itemToDisplay, setitemToDisplay] = useState(null)
    const {address} = useSelector(state => state.walletProvider);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    const loadPublicUniverses = async () => {
        try {
            setLoading(true);
            const result = await getPublicUniverses();
            const sorted = result.sort(universeSort);
            setUniverses(sorted);
        } catch ( error ){
            dispatch(uiShowAlert('error', 'Collectors Universes', 'We have been unable to load the public universes. Please refresh and try again later.'));
        } finally {
            setLoading(false)
        }
        
    }
    useEffect(() => {
        loadPublicUniverses();
    }, [])

    

    const itemsSort = (a,b) => {
        if (a.zIndex < b.zIndex)
            return -1
        else
            return 1
    }

    const universeSort = (a,b) => {
        if (a.favourites?.length || 0 > b.favourites?.length || 0)
            return -1
        if (a.favourites?.length || 0 < b.favourites?.length || 0)
            return 1

        return 0;
    }

    const handleSphereClick = async (itemToShow) => {
        setitemToDisplay(itemToShow)
        setOpen(true)
    }

    const handleFavouriteClick = async (universe) => {
        if (!address){
            dispatch(showWalletProviderModal());
            return;
        }

        await setUniverseFavourite(universe.owner, address)
        if (universe.favourites?.find(val => val === address)){
            const index = universe.favourites.indexOf(address)
            universe.favourites.splice(index, 1)
        } else {
            universe.favourites.push(address)
        }

        setUniverses(universes => universes.map(val => val.owner === universe.owner ? universe : val));
    }
    
    
  return (
    <div className='space'>
        <Container>
            <Box paddingTop={5}>
            <Link 
                underline="hover"
                href='/'
            >
                <Typography
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.2em'
                variant='h6'
                textTransform={'uppercase'}
                >
                <ArrowBackIosIcon/> Home
                </Typography>
            </Link>
            </Box>
        </Container>
            <Grid
                container
                direction={'column'}
                alignItems='center'
                justifyContent={'center'}
                spacing={3}        
            >
                <Container>
                <Grid item>
                    <Typography
                        fontFamily={theme.palette.font.headline_regular}
                        letterSpacing='0.2em'
                        variant='h4'
                        align='center'
                        textTransform={'uppercase'}
                    >
                        Collectors Universes
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography
                        fontFamily={theme.palette.font.content}
                        variant='body1'
                        align='center'
                    >
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque explicabo facere porro dignissimos autem tenetur, eveniet itaque alias! Corporis maiores soluta rem commodi quis libero repellat non adipisci sit deleniti?
                    </Typography>
                </Grid>
            </Container>
                {(loading) ? (
                    <Box className='center-screen'>
                        <CircularProgress/>
                    </Box>
                ) : (
                    <Grid item>
                        <Grid container direction='row' spacing={2} alignItems='center' justifyContent={'center'}>
                            {universes.map((universe, index) => (
                                <Grid item key={index}>
                                    <svg 
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='1500px'
                                        height='500px'
                                        style={{position:'relative', border:1, borderStyle:'solid', borderColor:'#404040'}}
                                        
                                    >
                                        <image href={backgroud}/>
                                        
                                        {universe.items.sort(itemsSort).map((item, index) => (
                                                <image 
                                                    href={(item.base64) ? item.base64 : loadingGif} 
                                                    key={index} 
                                                    style={{
                                                        cursor:'pointer'
                                                    }}
                                                    x={item.position.x}
                                                    y={item.position.y}
                                                    width={(item.base64) ? `${(item.size * 30)}px` : '70px'}
                                                    id={item.tokenId}
                                                    onClick={() => handleSphereClick(item)}
                                                />
                                        ))}
                                    </svg>
                                    <Box display='flex' justifyContent={'flex-end'} alignItems='center'>
                                        Creator: {shortenAddress(universe.owner)}
                                        <IconButton  
                                            color={universe.favourites?.find(val => val === address) ? 'warning' : 'primary'}
                                            size='small'
                                            onClick={() => handleFavouriteClick(universe)}
                                        >
                                            <FavoriteIcon/> {(universe.favourites) ? universe.favourites.length : 0}
                                        </IconButton>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}

            </Grid>

            <StellaLunaDialog item={itemToDisplay}  open={open} setOpen={setOpen}/>
    </div>
  )
}
