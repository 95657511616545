import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'

import gif from 'images/mintPhases/minting.GIF';

export const MintingDialog = props => {
    const {open} = props;

    
    return (
    <Dialog open={open}  elevation={0}>
        <img src={gif} alt='minting' width='400px'/>
    </Dialog>
  )
}

MintingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
}

