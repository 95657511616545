import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

export const ConfirmationDialog = props => {
  const {open, title, body, onAccept, onClose} = props;
  const theme = useTheme();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle  >
        <Typography fontFamily={theme.palette.font.headline_regular} textTransform='uppercase'>
          {title}
        </Typography>
        
      </DialogTitle>

      <DialogContent>
        <Typography fontFamily={theme.palette.font.content} gutterBottom>
          {body}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button 
          sx={{height:'30px', borderRadius: 0, color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} 
          onClick={onAccept}
        >
          Continue
        </Button>

        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    body: PropTypes.object.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

