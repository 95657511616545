import React, { useState } from 'react'

import { Button, Grid, Typography} from '@mui/material'
import { useTheme } from '@mui/system'
import { Size } from './Size';
import { Position } from './Position';
import { useDispatch, useSelector } from 'react-redux';
import { removeUniverseItem, saveUniverse, setNeedSave } from 'reducers/universeReducer/universeActions';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';


export const Controls = () => {
  const theme = useTheme();
  const [saving, setSaving] = useState(false)
  const {items, needSave} = useSelector(state => state.universe);
  const {address} = useSelector(state => state.walletProvider);
  const dispatch = useDispatch()
  
  const handleReset = () => {
    // removes all spheres from the lab
    for (const item of items){
      dispatch(removeUniverseItem(item));
    }
    dispatch(setNeedSave(true))
  }

  const handleSave = async () => {
    try {
      setSaving(true)
      const myUniverse = [...items];
      for (const item of myUniverse){
        // delete item.base64
      }

      await saveUniverse(address, {items: myUniverse});
      dispatch(setNeedSave(false))
      dispatch(uiShowAlert('success', 'My Universe', 'Saved successfully.'))
    } catch ( error ) {
      console.log(error);
      dispatch(uiShowAlert('error', 'My Universe', 'We have been unable to save your universe. Please try in a few minutes.'))
    } finally {
      setSaving(false)
    }

  }
  return (
    <Grid
      container
      direction={'column'}
      spacing={1}    
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item >
        <Size/>
      </Grid>
         
      <Grid item >
        <Position/>
        </Grid>

      <Grid item>
        <Button
            variant='outlined'
            disabled={!needSave}  
            onClick={handleSave}
            sx={{height:'30px', borderRadius: 0, color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} 
        >
            <Typography fontFamily={theme.palette.font.content} variant='button' textTransform={'uppercase'}>
                {(saving) ? 'Saving...' : 'Save your Universe'}
            </Typography>
        </Button>
      </Grid>

      

      <Grid item>
        <Button
            variant='outlined'
            // disabled={disabled}  
            onClick={handleReset}
            sx={{height:'30px', borderRadius: 0, color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} 
        >
            <Typography fontFamily={theme.palette.font.content} variant='button' textTransform={'uppercase'}>
                Reset
            </Typography>
        </Button>
      </Grid>

    </Grid>
  )
}
