const apiToken = process.env.REACT_APP_API_TOKEN;
const apiURL = process.env.REACT_APP_API_URL;

export const subscribe = async (email) => {
    const data = {email};
    const url = apiURL.concat('subscribe/');    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
}

export const requestAuthMessage = async (address) => {
  const data = {address};
  const url = apiURL.concat('auth/getMessage');    
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    return response.json(); 
}

export const requestAuthToken = async (address, signature) => {
  const data = {address, signature};
  const url = apiURL.concat('auth/getToken');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    return response.json(); 
}