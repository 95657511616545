
import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { createCanvas, loadImage } from 'canvas';
import svgToDataURL from 'svg-to-dataurl';
import loadingGif from 'images/universe/loading.gif'
import { StellaLunaDialog } from 'views/Dashboard/components/StellaLunaDialog'
import { ConfirmationDialog } from 'common/ConfirmationDialog';
import { makeUniversePublic } from 'reducers/universeReducer/universeActions';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import background from 'images/universe/universe_bg_1500x500.jpg'
import { getBase64FromUrl } from 'utils/base64';

export const ReadOnly = () => {
    const theme = useTheme();
    const {items} = useSelector(state => state.universe)
    const {address} = useSelector(state => state.walletProvider);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [display, setDisplay] = useState(false)
    const [item, setItem] = useState(null)
    const svgRef = useRef();
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [base64Background, setBase64Background] = useState()
    
    const handleSphereClick = async (itemToShow) => {
        setItem(itemToShow)
        setDisplay(true)
    }

    const handleExportImage = async () => {
        let svgData = new XMLSerializer().serializeToString(svgRef.current);
        const dataUrl = svgToDataURL(svgData);
        const image = await loadImage(dataUrl);
        const canvas = createCanvas(1500, 500)
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0)
        const url = canvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.download = 'myUniverse.jpg';
        link.href = url;
        link.click();
    }

    useEffect(() => {
      if (display)
        setOpen(true)
    }, [item])

    useEffect(() => {
      getBase64FromUrl(background).then((base64) => {
        setBase64Background(base64)
      } )
    }, [])
    
    

    const itemsSort = (a,b) => {
        if (a.zIndex < b.zIndex)
            return -1
        else
            return 1
    }

    const handleMakePublic = () => {
        setConfirmationOpen(true);
    }

    const onAcceptMakePublic = async () => {
        setConfirmationOpen(false);
        try {
            await makeUniversePublic(address, {owner: address, items: [...items]})
            dispatch(uiShowAlert('success', 'Public universe', 'Your universe is public for the world to see!'))
        } catch ( error ){
            dispatch(uiShowAlert('error', 'Public universe', 'There was an issue making your universe public. Please refresh and try again.'))
        } 
    }
  return (
    <Grid
        container
        direction={'column'}
        spacing={2}
        justifyContent="flex-start" alignItems="flex-end"
    >
        <Grid>
            <svg 
                xmlns='http://www.w3.org/2000/svg'
                width='1500px'
                height='500px'
                ref={svgRef}
                style={{position:'relative', border:1, borderStyle:'solid', borderColor:'#404040'}}
                
            >
                <image
                    href={base64Background}
                    key='background'
                    width='1500px'
                    height='500px'
                    x={0}
                    y={0}
                />

                {items.sort(itemsSort).map((item, index) => (
                        <image 
                            href={(item.base64) ? item.base64 : loadingGif} 
                            key={index} 
                            style={{
                                cursor:'pointer'
                            }}
                            x={item.position.x}
                            y={item.position.y}
                            width={(item.base64) ? `${(item.size * 30)}px` : '70px'}
                            id={item.tokenId}
                            onClick={() => handleSphereClick(item)}
                        />
                ))}
            </svg>
        </Grid>
            <Grid item >
                <Grid container direction={'column'} spacing={2} justifyContent="flex-start" alignItems="flex-end">
                <Grid item>
                <Button
                    variant='outlined'
                    // disabled={disabled}  
                    onClick={handleMakePublic}
                    sx={{height:'30px', borderRadius: 0, color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} 
                >
                    <Typography fontFamily={theme.palette.font.content} variant='button' textTransform={'uppercase'}>
                        Make public
                    </Typography>
                </Button>
            </Grid>

            <Grid item>
                <Button
                    variant='outlined'
                    // disabled={disabled}  
                    onClick={handleExportImage}
                    sx={{height:'30px', borderRadius: 0, color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} 
                >
                    <Typography fontFamily={theme.palette.font.content} variant='button' textTransform={'uppercase'}>
                        Export image
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    </Grid>

        <StellaLunaDialog item={item}  open={open} setOpen={setOpen}/>
        <ConfirmationDialog 
            open={confirmationOpen} 
            title='My universe'
            body={<span>Making your universe public will share it with the STELLALUNA community.<br/>This will also erase any previous shared universe and their stats.<br/><br/>Are you sure you want to continue? </span>} 
            onClose={() => setConfirmationOpen(false)} 
            onAccept={onAcceptMakePublic}
        />
    </Grid>
  )
}
