import React, {useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Divider, Menu, MenuItem, Typography} from '@mui/material';
import { shortenAddress, toETH } from 'utils/blockchain';
import { disconnectProvider, showWalletProviderModal } from 'reducers/walletProviderReducer/walletProviderActions';
import { CustomMusicPlayer } from 'views/Home/CustomMusicPlayer';
import { uiOpenFundWalletDialog, uiSetView } from 'reducers/uiReducer/uiActions';



const Topbar = ({
  themeMode,
  themeToggler,
  setThemePalette,
  onSidebarOpen,
  paletteType,
}) => {
  const location = useLocation();  
  const {address} = useSelector(state => state.walletProvider);
  const {stats} = useSelector(state => state.blockchain);
  const showWallet = (process.env.REACT_APP_SHOW_CONNECT_WALLET === 'false' ? false : true)

  const {total, withoutPhysical, withPhysical, date} = stats;
  const dispatch = useDispatch();
  const activeMenu = useRef('home')
  const handleMenuClick = (view) => {
    if (view === 'fundWallet'){
      dispatch(uiOpenFundWalletDialog())
      handleClose();
      return;
    }

    if (view === activeMenu.current){
      dispatch(uiSetView('home'))
      handleClose();
      activeMenu.current = 'home'
    } else{
      dispatch(uiSetView(view))
      handleClose();
      activeMenu.current = view
    }
    
  }
  const handleClick = async (event) => {
    if (address){
      setAnchorEl(event.currentTarget);
    } else {
      dispatch(showWalletProviderModal());
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDisconnect = () => {
    dispatch(disconnectProvider())
    dispatch(uiSetView('home'))
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Box display={'flex'} alignItems={'center'}>

        {(showWallet) && (
          <Box marginRight={{ xs: 1, sm: 2 }}>
            <Button 
              variant='outlined'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}
              onClick={handleClick}
            >
              <Typography fontFamily={theme.palette.font.content} variant='button'>
                {(address) ? shortenAddress(address) : 'CONNECT WALLET'}
              </Typography>
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {(location.pathname === '/') ? (
                  <div>
                    <MenuItem onClick={() => handleMenuClick('dashboard')}sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}>
                      {(activeMenu.current === 'dashboard') ? 'Home' : 'Dashboard'}
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClick('myUniverse')} sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}>
                      {(activeMenu.current === 'myUniverse') ? 'Home' : 'My Universe'}
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClick('fundWallet')} sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}>
                      Fund Wallet
                    </MenuItem>
                    <MenuItem sx={{color: 'black', backgroundColor:'white'}}><Divider  color='#000000' sx={{ height: 2, width: '92px'}}/> </MenuItem>
                  </div>
                ) : (
                  <div>
                    <MenuItem href={'/'} component="a"  sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}>
                      Home
                    </MenuItem>
                  </div>
                )}
                <MenuItem onClick={handleDisconnect} sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}>
                  Disconnect
                </MenuItem>
                  
              </Menu>
          </Box>
        )}
      </Box>
      
      <Box display="flex" alignItems={'center'}>
        <CustomMusicPlayer/>
      </Box>

      {(!isMobile) && (
        <Box display="flex" alignItems={'center'}>
          <Box sx={{ display: { md: 'flex' } }} alignItems={'center'}>
            <Typography
              fontFamily={theme.palette.font.content}
            >
              Dates: {date}<br/>
              Supply: {total}<br/>
              Price: {(withoutPhysical && withPhysical) ? <span>{toETH(withoutPhysical)} - {toETH(withPhysical)}</span> : ''} ETH
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;
