import React from 'react'
import {Provider} from 'react-redux'
import { Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import {store} from './store'
import { Routes } from 'router/Routes';

import './StellaLuna.css'
import { CustomAlert } from 'common/CustomAlert';

const browserHistory = createBrowserHistory({forceRefresh:true});
export const StellaLuna = () => {
  return (
    
      <Provider store={store}>
          <Router history={browserHistory}>
              <Routes/>
          </Router>
          <CustomAlert/>
      </Provider>
  )
}
