import React, {useEffect} from 'react'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { DailyPiece } from './components/DailyPiece';
import { DailyCountdown } from './components/DailyCountdown';
import { DailyOption } from './components/DailyOption';
import { DailyPay } from './components/DailyPay';
import { DailyPhysical } from './components/DailyPhysical';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { loadNFTStats } from 'reducers/blockchainReducer/blockchainActions';
import { Box } from '@mui/system';

export const DailyMint = () => {
    const theme = useTheme();
    const {dailyMintItem} = useSelector(state => state.mint);

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(loadNFTStats())
    }, [])
    
  return (
    <Box>
        {(dailyMintItem) && (
            <Box style={{position:'absolute', top:(isMobile ? 300 : 0), left:0, zIndex:0}} width='100%'>
                <video id='art' autoPlay loop muted width={'100%'} height={'100%'}>
                    <source src={(dailyMintItem?.dailyVideo) ? dailyMintItem?.dailyVideo : 'https://firebasestorage.googleapis.com/v0/b/stellaluna-dev.appspot.com/o/artistSelected%2F1%2Ftest_h264.mp4?alt=media&token=9dc15c70-1ced-4cd4-903b-16bd10276433'} type='video/mp4'/>
                </video>
            </Box>
        )}
        <Grid
            container
            direction={'column'}
            justifyContent='center'
            alignItems={'center'}
            spacing={2}
        >  
            <Grid item zIndex={1}>
                <Typography
                    fontFamily={theme.palette.font.headline_regular}
                    letterSpacing='0.2em'
                    variant='h5'
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    paddingTop={5}
                >
                    purchase todays
                </Typography>

                <Typography
                    fontFamily={theme.palette.font.headline_regular}
                    letterSpacing='0.2em'
                    variant='h3'
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    paddingBottom={1}
                >
                    StellaLuna
                </Typography>
            </Grid>

            <Grid item zIndex={1}>
                <Typography
                    fontFamily={theme.palette.font.headline_regular}
                    variant='h6'
                    textTransform={'uppercase'}
                    textAlign={'center'}
                >
                    Fcfs Phase
                </Typography>
                <Typography
                    fontFamily={theme.palette.font.headline_content}
                    variant='h5'
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    fontWeight={700}
                >
                    only one unique piece per day
                </Typography>
            </Grid>

            <Grid item zIndex={1}>
                <DailyPiece/>
            </Grid>

            <Grid item zIndex={1}>
                <DailyCountdown/>
            </Grid>

            <Grid item zIndex={1}>
                <DailyOption/>
            </Grid>

            <Grid item zIndex={1}>
                <DailyPay/>
            </Grid>

            <Grid item zIndex={1}>
                <DailyPhysical/>
            </Grid>
        </Grid>
    </Box>
  )
}
