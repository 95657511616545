import React, {useState, useEffect} from 'react'

import {isMobile} from 'react-device-detect';

import logo from 'images/subscribe/logo.png';
import bgblur from 'images/subscribe/bgblur.png';
import discord from 'images/subscribe/discord.gif';
import insta from 'images/subscribe/insta.png';
import twitter from 'images/subscribe/twitter.png';


import './Subscribe.css'
import { Button, Grid, InputAdornment, Snackbar, TextField } from '@mui/material';
import { EmailRounded } from '@mui/icons-material';
import { subscribe } from 'utils/api';

export const Subscribe = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [canvas, setCanvas] = useState();
  const [c, setC] = useState()
  
    let w;
    let h;

    const setCanvasExtents = () => {
        w = window.innerWidth //document.body.clientWidth;
        h = window.innerHeight //document.body.clientHeight;
        canvas.width = w;
        canvas.height = h;
      };

    const makeStars = count => {
        const out = [];
        for (let i = 0; i < count; i++) {
            const s = {
            x: Math.random() * 1600 - 800,
            y: Math.random() * 900 - 450,
            z: Math.random() * 1000
            };
            out.push(s);
        }
        return out;
    };


    let stars = makeStars(2000);

    const clear = () => {
      c.fillStyle = "black";
      c.fillRect(0, 0, canvas.width, canvas.height);
    };

    const putPixel = (x, y, brightness) => {
      const intensity = brightness * 1000;
      const rgb = "rgb(" + intensity + "," + intensity + "," + intensity + ")";
      c.fillStyle = rgb;
      c.fillRect(x, y, 1, 1);
    };

    const moveStars = distance => {
      const count = stars.length;
      for (var i = 0; i < count; i++) {
        const s = stars[i];
        s.z -= distance;
        while (s.z <= 1) {
          s.z += 1000;
        }
      }
    };

    let prevTime;
    const init = time => {
      prevTime = time;
      window.requestAnimationFrame(tick);
    };

    const tick = time => {
      let elapsed = time - prevTime;
      prevTime = time;

      moveStars(elapsed * 0.1);

      clear();

      const cx = w / 2;
      const cy = h / 2;

      const count = stars.length;
      for (var i = 0; i < count; i++) {
        const star = stars[i];

        const x = cx + star.x / (star.z * 0.001);
        const y = cy + star.y / (star.z * 0.001);

        if (x < 0 || x >= w || y < 0 || y >= h) {
          continue;
        }

        const d = star.z / 1000.0;
        const b = 1 - d * d;

        putPixel(x, y, b);
      }

      window.requestAnimationFrame(tick);
    };

    

    useEffect(() => {
      const can = document.getElementById("canvas")
      setCanvas(can);
      const cObj = can.getContext("2d")
      setC(cObj);
    }, [])

    useEffect(() => {
      if (canvas){
      setCanvasExtents();
      window.requestAnimationFrame(init);  
    }
    }, [canvas])

    const [email, setEmail] = useState(null);
    
    const handleEmailChange = (e) => {
      setEmail(e.target.value)
    }

    function isValidEmail(email) 
    {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handleFormSubmit = async (e) => {
      e.preventDefault();
      if (isValidEmail(email)){
        try {
          await subscribe(email)
          setMessage('Successfully subscribed!');
          setEmail('');
          setOpen(true);
        } catch ( error ) {
          setMessage('Unable to subscribe you. Retry later please.');
          setOpen(true);
        }
      }

    }
    
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState();
    const handleClose = () => {
      setOpen(false);
    }
   
  return (
    <div className='subscribe'>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
      />
        <div className="container">
          <canvas id="canvas" className='subscription-canvas'/>
          
          <div className="logo">
              <img src={logo} width='100%'/>
          </div>
          <div className="bgimage">
            <img src={bgblur}/>
          </div>
          <div className="description" style={{paddingTop:(isMobile) ? '120px' : '1px'}}>
              The 360° interactive art experience that will change the way you experienced NFTs before.<br/>
              {(isMobile) ? (<div><br/><br/></div>) : (<div></div>)}
              A collection of 7.777 interactive NFTs going live in the future.<br/>
              {(isMobile) ? (<div><br/><br/></div>) : (<div></div>)}
              Subscribe and follow to receive the newest updates.
              {(isMobile) ? (<div><br/><br/></div>) : (<div></div>)}
          </div>
          
          <div className="content" style={{marginTop:(isMobile) ? '350px' : '100px'}}>
          
              <form onSubmit={handleFormSubmit} className="subscription">
                  <input type="hidden" name="_captcha" value="false"/>
                  <input type="hidden" name="_next" value="https://www.stellalunanft.com/thankyou.html"/>
                  <input className="add-email" type="email" name="email" placeholder="Enter your email address" value={email} onChange={handleEmailChange}/>
                  <button className="submit-email" type="submit" >
                      <span className="before-submit">Subscribe</span>
                      <span className="after-submit">Successfully subscribed!</span>
                  </button>
              </form>
          </div>
          <div className="icons" style={{marginTop:(isMobile) ? '500px' : '350px'}}>
              <a href="https://twitter.com/Stellaluna_nft" target="_blank"><img src={twitter} alt="Twitter"/></a>
              <a href="https://instagram.com/Stellaluna_nft" target="_blank"><img src={insta} alt="Instagram"/></a>
              <a href="#"><img src={discord} alt="Discord"/></a>
          </div>
          </div>
      </div>
  )
}
