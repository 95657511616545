import React from 'react'
import { useTheme } from '@mui/system'
import { Button, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { setNeedSave, updateUniverseItem } from 'reducers/universeReducer/universeActions';

export const Position = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const {selected, items} = useSelector(state=> state.universe);

    const handleIncrease = () => {
      const item = items.find(val => val.tokenId === selected.tokenId)
      if (item.zIndex < 21)
      dispatch(updateUniverseItem({...item, zIndex: item.zIndex+1}))
      dispatch(setNeedSave(true))
    }

    const handleDecrease = () => {
      const item = items.find(val => val.tokenId === selected.tokenId)
      if (item.zIndex > 0)
        dispatch(updateUniverseItem({...item, zIndex: item.zIndex-1}))
        dispatch(setNeedSave(true))
    }
  return (
    
    <Grid container direction='row'>
        <Grid item >
            <Button  
              variant='outlined'
              disabled={(!selected)}  
              onClick={handleDecrease}
              sx={{height:'30px', borderRadius: 0, color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} 
            >
              <Typography fontFamily={theme.palette.font.content} variant='button' textTransform={'uppercase'}>
                  back
              </Typography>
            </Button>
        </Grid>
        <Grid item >
            <Typography
                fontFamily={theme.palette.font.content}
                variant='h6'
                fontWeight={700}
                align={'center'}
                display='flex'
                alignItems={'center'}
                textTransform='uppercase'
            >
                &nbsp;position&nbsp;
            </Typography>
        </Grid>
          
        <Grid item >
            <Button  
              variant='outlined'
              disabled={(!selected)}  
              onClick={handleIncrease}
              sx={{height:'30px', borderRadius: 0, color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}} 
            >
              <Typography fontFamily={theme.palette.font.content} variant='button' textTransform={'uppercase'}>
                  front
              </Typography>
            </Button>
        </Grid>
    </Grid>
  )
}
