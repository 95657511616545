import React from 'react';
import Grid from '@mui/material/Grid';

const Footer = () => (
  <Grid>
    
  </Grid>
);

export default Footer;
