import React, {useState, useEffect} from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { showWalletProviderModal } from 'reducers/walletProviderReducer/walletProviderActions';
import { BigNumber, ethers } from 'ethers'

import abi from 'utils/StellaLuna.json'
import { MintingDialog } from 'views/Home/MintPhase/components/Common/MintingDialog';
import { db } from 'config/firebase';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import { setDailyMintArtItem } from 'reducers/mintReducer/mintActions';
import { setWithPhysical } from 'reducers/blockchainReducer/blockchainActions';

export const DailyPay = () => {
    const {dailyMintItem, dailyMintZero, dailyMintOption} = useSelector(state => state.mint);
    const {address, provider} = useSelector(state => state.walletProvider);
    const {stats} = useSelector(state => state.blockchain);
    const {withoutPhysical, withPhysical} = stats;
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)


    const validateIsDisabled = () => {
        if (dailyMintItem && !dailyMintItem?.minted &&  dailyMintZero && dailyMintOption != 0)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }

    useEffect(() => {
      validateIsDisabled();
    }, [dailyMintItem, dailyMintZero, dailyMintOption])
    

    const hasBalance = async (howMuch) => {
        const balance = await provider.getBalance(address);
        
        return balance.gt(howMuch);
    }
    
    const getPrice = (option) => {
        if (option === 1){
            return BigNumber.from(withoutPhysical)
        } else
            return BigNumber.from(withPhysical)
    }
    
    const handleMintWallet = async () => {
        if (!address){
            dispatch(showWalletProviderModal());
            return;
        }

        const price = getPrice(dailyMintOption);
        const withBalance = await hasBalance(price);

        if (!withBalance){
            // user doesn't have enought balance
            dispatch(uiShowAlert('info', 'Not enought balance', `You don't have enought balance for this operation to succeed. Please add more funds or pick another option.`));
            return;
        }

        try {
            // we are ready to sign
            const signer = provider.getSigner();
            const stellaLunaNFT = new ethers.Contract(process.env.REACT_APP_STELLALUNA_ADDRESS, abi.abi, signer);
            const tx = await stellaLunaNFT.dailyMint(address, dailyMintOption, {value: price});

            // if the wallet minted with option, we record it
            try {
                if (dailyMintOption == 2){
                    setWithPhysical(address);
                }
            } catch ( error ) {
                // we can ignore this
            }
            
            setOpen(true)
            
            await tx.wait();
            await db.collection('drop1').doc('artEngine').collection('dailyQueue').doc(dailyMintItem.docId.toString()).set({minted: true}, {merge: true})
            dispatch(setDailyMintArtItem({...dailyMintItem, minted: true}))
            
            dispatch(uiShowAlert('success', 'Successfull Mint', 'Congratulations, minting process has started!'));
        } catch ( error ) {
            console.log(error);
            if (error.message.includes('Only one mint per day allowed.')){
                dispatch(uiShowAlert('error', 'Unable to mint STELLALUNA', `Daily mint already minted by someone else! Sorry but you will have to wait until tomorrow.`));
            } else {
                dispatch(uiShowAlert('error', 'Unable to mint STELLALUNA', `There was an error minting your STELLALUNA. Please refresh and try again.`));
            }
        } finally {
            // dispatch(uiCloseBackdrop());
            setOpen(false)
        }
    }
  return (
    <div>
        <Grid
            container
            direction={'row'}
            justifyContent='center'
            alignItems={'center'}
            spacing={2}
        >
            <Grid item>
                <Button
                    sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}
                    onClick={handleMintWallet}
                    disabled={isDisabled}
                >
                    <Typography textTransform={'uppercase'} variant='body1'>
                        Buy with your wallet
                    </Typography>
                </Button>
            </Grid>

            <Grid item>
                <CrossmintPayButton
                    clientId={process.env.REACT_APP_CROSSMINT_CLIENT_ID}
                    mintConfig={{"type":"erc-721","totalPrice":(dailyMintOption === 0) ? 0 : ethers.utils.formatEther(getPrice(dailyMintOption)),"option":dailyMintOption}}
                    environment={process.env.REACT_APP_CROSSMINT_ENVIRONMENT}
                    disabled={isDisabled}
                />
            </Grid>
        </Grid>
        <MintingDialog open={open} setOpen={setOpen}/>
    </div>
  )
}
