import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/system';
import {utils, BigNumber} from 'ethers';
import EmailIcon from '@mui/icons-material/Email';
import { DialogContent, FormControl, Grid, InputAdornment, OutlinedInput, Typography, useTheme } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum } from '@fortawesome/free-brands-svg-icons'


import { validateEmail } from 'utils/email';
import {shortenAddress} from 'utils/blockchain';
import { db } from 'config/firebase';


import { uiCloseFundWalletDialog } from 'reducers/uiReducer/uiActions';
import { myReadOnlyWeb3 } from 'utils/blockchain';



export const FundWallet = () => {
    const theme = useTheme()
    const {openFundWallet} = useSelector(state => state.ui);
    const {address} = useSelector(state => state.walletProvider);
    const [email, setEmail] = useState('');
    const [balance, setBalance] = useState(0)
    const [isDisabled, setIsDisabled] = useState(true)
    const [amount, setAmount] = useState(0)
    const [amountWithError, setAmountWithError] = useState(true);
    const web3 = myReadOnlyWeb3();
    const network = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(uiCloseFundWalletDialog());
    }

    const handleEmailChange = (e) => {
      setEmail(e.target.value)
      if (validateEmail(e.target.value))
        setIsDisabled(false)
      else
        setIsDisabled(true)
    }

    const getEmail = async () => {
      try {
          const snapshot = await db.collection('users').doc(address).get();
          const user = snapshot.data();
          setEmail(user.email);

          if (validateEmail(user.email))
              setIsDisabled(false)
          else
              setIsDisabled(true)
      } catch ( error ){
          // ignoring
      }
    }

    const handlePurchase = async (e) => {
      // we save the email
      await saveEmail();

      // if non production , we trigger the fake deposit.
      if (network === 'goerli'){
          try {
              const {purchaseViewToken, purchase} = e.payload;
              const {id} = purchase;
              const url = `https://api-instant-staging.supozu.com/api/widget/testing/purchase/${id}/release?secret=${purchaseViewToken}`
              await fetch(url, {method: 'POST'});
          } catch ( error ){
              // we ignore any errors.
          }
      }

    }

    const saveEmail = async () => {
      try {
          await db.collection('users').doc(address).update({email});
      } catch ( error ){
          // ignoring
      }
    }

    const handleAmountChange = (e) => {
      const value = e.target.value;
      setAmount(value)
      if (isNaN(value))
          setAmountWithError(true)
      else {
          if (Number(value) === 0)
              setAmountWithError(true)
          else 
              setAmountWithError(false)
      }
    }
  

    const getBalance = async () => {
      console.log(web3);
        const myBalance = await web3.eth.getBalance(address);
        const number = BigNumber.from(myBalance);
        const remainder = number.mod(1e14);
        setBalance(utils.formatEther(number.sub(remainder)));
    }
    
    useEffect(() => {
      if (address && openFundWallet){
        // retrieve the address email
        getEmail();
        getBalance();
      } else {
        handleClose()
      }
    }, [openFundWallet])
    
    const onClick = () => {
      new RampInstantSDK({
          hostAppName: 'STELLALUNA',
          hostLogoUrl: 'https://storage.googleapis.com/stellaluna_metadata/freeMintImage.jpeg',
          swapAmount: (network === 'goerli') ? '5000000000000000' : web3.utils.toWei(amount), // 150 ETH in wei
          swapAsset: 'ETH_ETH',
          userEmailAddress: email,
          userAddress: address,
          // url: process.env.REACT_APP_RAMP_URL,
          variant: 'embedded-desktop',
          containerNode: document.getElementById('ramp-container')
          }).on('WIDGET_CONFIG_DONE', event => setIsDisabled(true))
          .on('WIDGET_CLOSE', event => setIsDisabled(false))
          .on('PURCHASE_CREATED', event => handlePurchase(event))
          .show();
  }

  return (
    <Dialog
        fullScreen
        open={openFundWallet}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative', backgroundColor:'black' }} elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography fontFamily={theme.palette.font.content}  variant="h6" component="div">
                Close
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{backgroundColor: 'black'}}>
          <Grid
            container
            direction='row'
            spacing={2}
            justifyContent="center"
          >
            <Grid item xs={4}>
              <Grid 
                container 
                direction='column' 
                spacing={2}
              >
                <Grid item>
                  <Typography
                    fontFamily={theme.palette.font.headline_regular}
                    letterSpacing='0.2em'
                    variant='h4'
                    align='center'
                    textTransform={'uppercase'}
                  >
                    Fund your Wallet
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                      fontFamily={theme.palette.font.content}
                      letterSpacing='0.2em'
                      variant='h4'
                      align='center'
                      paddingBottom={2}
                      textTransform={'uppercase'}
                  >
                    {shortenAddress(address)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontFamily={theme.palette.font.content} variant='h5'>
                      Wallet Balance: <FontAwesomeIcon  icon={faEthereum} /> {balance}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container direction='row' display='flex' alignItems={'center'}>
                    <Grid item xs={4}>
                      <Typography fontFamily={theme.palette.font.content} variant='h5' display={'inline'}>
                        Email: &nbsp;
                      </Typography>

                    </Grid>
                      <FormControl>
                          <OutlinedInput
                              id="outlined-adornment-email"
                              value={email}
                              style={{backgroundColor: 'white', color:'black'}}
                              fullWidth
                              onChange={handleEmailChange}
                              autoComplete={'false'}
                              autoFocus={false}
                              startAdornment={<InputAdornment position="start"><EmailIcon /></InputAdornment>}
                          />
                      </FormControl>

                    <Grid item xs={8}>
                      
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item >
                  <Grid container direction='row' display='flex' alignItems={'center'}>
                    <Grid item xs={4}>
                      <Typography fontFamily={theme.palette.font.content} variant='h5' display={'inline'}>
                        Amount: &nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl>
                          <OutlinedInput
                              id="outlined-adornment-amount"
                              value={amount}
                              onChange={handleAmountChange}
                              autoComplete={'false'}
                              style={{backgroundColor: 'white', color:'black'}}
                              error={amountWithError}
                              autoFocus={false}
                              startAdornment={<InputAdornment position="start"><FontAwesomeIcon  icon={faEthereum} /></InputAdornment>}
                          />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                    <Button 
                      variant='outlined' 
                      sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}
                      onClick={onClick} 
                      fullWidth
                      disabled={(isDisabled || amountWithError)}
                    >
                      <Typography fontFamily={theme.palette.font.content} variant='h5'>
                        Buy ETH
                      </Typography>
                    </Button>
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={8}>
              <Box id='ramp-container' height={'590px'} width='895px'/>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
  )
}
