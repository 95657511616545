import React from 'react'

import { Grid, Typography, useTheme } from '@mui/material'
import { AddressForm } from '../AddressForm';

export const Address = () => {
    const theme = useTheme();

  return (
    <Grid 
        container
        direction='column'
        justifyContent="center"
        alignItems="center"
        spacing={3}
    >
        <Grid item>
            <Typography
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.2em'
                variant='h4'
                textTransform={'uppercase'}
            >
                Add your address
            </Typography>
        </Grid>

        <Grid item>
            <Typography
              fontFamily={theme.palette.font.content}
              variant='body'
              textTransform={'uppercase'}
            >
                in order to receive your physical print
            </Typography>
        </Grid>

        <Grid item paddingTop={4}>
            <AddressForm/>
        </Grid>

    </Grid>
  )
}
