export const adminTypes = {
    adminAddNebula: '[admin] adds a new nebula trait',
    adminUpdateNebula: '[admin] updates an existing nebula trait',

    adminAddNebulaTrait: '[admin] adds a new nebulatrait trait',
    adminUpdateNebulaTrait: '[admin] updates an existing nebula trait',

    adminAddOutside: '[admin] adds a new outside trait',
    adminUpdateOutside: '[admin] updates an existing outside trait',

    adminAddSound: '[admin] adds a new sound trait',
    adminUpdateSound: '[admin] updates an existing sound trait',

    adminAddStar: '[admin] adds a new star trait',
    adminUpdateStar: '[admin] updates an existing star trait',
    
    adminAddArtistSelected: '[admin] adds a new artistSelected trait',
    adminUpdateArtistSelected: '[admin] updates an existing artistSelected trait',

    adminTraitFilterAdd: '[admin] add a new trait filter',
    adminTraitFilterRemove: '[admin] removes a trait filter',
    
    adminSetArtAmount: '[admin] loads the amount of generated art'
}