import {mintTypes} from './mintTypes';

const initialState = {
    dailyMintItem: null,
    dailyMintZero: false,
    dailyMintOption: 0
}

export const mintReducer = (state = initialState, action) => {
    switch (action.type) {
        case mintTypes.setDailyMintArt:
            return {
                ...state,
                dailyMintItem: action.payload
            }

        case mintTypes.setDailyMintCountDownZero:
            return {
                ...state,
                dailyMintZero: action.payload
            }

        case mintTypes.setDailyMintOption:
            return {
                ...state,
                dailyMintOption: action.payload
            }
            
        default:
            return state
    }
}