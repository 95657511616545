import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { useDispatch } from 'react-redux';
import { traitAddFilter, traitRemoveFilter } from 'reducers/adminReducer/adminActions';

export const TraitCheckbox = props => {
    const {item} = props;
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch()
    const handleOnChange = () => {
        setChecked(checked => !checked);
        if (!checked){
            dispatch(traitAddFilter(item.usedIn));
        } else {
            dispatch(traitRemoveFilter(item.usedIn))
        }
    }
  return (
    <Grid
        container
        direction='row'
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
    >
        <Grid item>
            {item.name}
        </Grid>

        <Grid item>
        <FormControlLabel
          value="start"
          control={<Checkbox checked={checked} onChange={handleOnChange} color='primary' style={{color: 'white'}} />}
          label={item.amount}
          labelPlacement="start"
        />
        </Grid>

    </Grid>
  )
}

TraitCheckbox.propTypes = {
    item: PropTypes.object.isRequired
}
