import {adminTypes} from './adminTypes';

const initialState = {
    nebula: [],
    nebulaTrait: [],
    outside: [],
    sound: [],
    star: [],
    artistSelected: [],
    traitFilter: [],
    artAmount: 0
};


export const adminReducer = (state = initialState, action) => {
    // nebula
    switch (action.type) {
        case adminTypes.adminAddNebula:
            return {
                ...state,
                nebula: [...state.nebula, action.payload]
            }

        case adminTypes.adminUpdateNebula:
            return {
                ...state,
                nebula: [...state.nebula.map(val => val.name === action.payload.name)]
            }


        // nebulaTrait
        case adminTypes.adminAddNebulaTrait:
            return {
                ...state,
                nebulaTrait: [...state.nebulaTrait, action.payload]
            }

        case adminTypes.adminUpdateNebulaTrait:
            return {
                ...state,
                nebulaTrait: [...state.nebulaTrait.map(val => val.name === action.payload.name)]
            }

        // outside
        case adminTypes.adminAddOutside:
            return {
                ...state,
                outside: [...state.outside, action.payload]
            }

        case adminTypes.adminUpdateOutside:
            return {
                ...state,
                outside: [...state.outside.map(val => val.name === action.payload.name)]
            }

        // sound
        case adminTypes.adminAddSound:
            return {
                ...state,
                sound: [...state.sound, action.payload]
            }

        case adminTypes.adminUpdateSound:
            return {
                ...state,
                sound: [...state.sound.map(val => val.name === action.payload.name)]
            }

        // sound
        case adminTypes.adminAddStar:
            return {
                ...state,
                star: [...state.star, action.payload]
            }

        case adminTypes.adminUpdateStar:
            return {
                ...state,
                star: [...state.star.map(val => val.name === action.payload.name)]
            }

        // artistSelected
        case adminTypes.adminAddArtistSelected:
            return {
                ...state,
                artistSelected: [...state.artistSelected, action.payload]
            }

        case adminTypes.adminUpdateArtistSelected:
            return {
                ...state,
                artistSelected: [...state.artistSelected.map(val => val.name === action.payload.name)]
            }

        case adminTypes.adminTraitFilterAdd:
            return{
                ...state,
                traitFilter: [...state.traitFilter, action.payload]
            }
            
        
        case adminTypes.adminTraitFilterRemove:
            return{
                ...state,
                traitFilter: [...state.traitFilter.filter(val => val !== action.payload)]
            }

        case adminTypes.adminSetArtAmount:
            return {
                ...state,
                artAmount: action.payload
            }
        
        
        default:
            return state;
    }
}