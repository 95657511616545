import React, {useEffect, useState} from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import { db } from 'config/firebase';
import { BrowserView, MobileView } from 'react-device-detect';
import moment from 'moment';

import { DailyMetadata } from '../DailyMetadata/DailyMetadata';
import { useDispatch } from 'react-redux';
import { setDailyMintArtItem } from 'reducers/mintReducer/mintActions';
import transparent from './transparent.png'

export const DailyPiece = () => {
    const [item, setItem] = useState();
    const dispatch = useDispatch();
    const getArtPiece = async () => {
        const startDate = process.env.REACT_APP_DAILY_MINT_START_DATE;
        const date = moment.unix(startDate);
        const now = moment();
        const index = now.diff(date, 'day');
        
        const filter = db.collection('drop1').doc('artEngine').collection('dailyQueue').doc((index < 0) ? '0' : index.toString());
        const result = await filter.get();
        const docId = result.id;
        const item = result.data();
        const {artId, minted} = item;
        const snapshot = await db.collection('drop1').doc('artEngine').collection('metadata').doc(artId.toString()).get()
        const value = snapshot.data();
        setItem({...value, minted, docId});
        dispatch(setDailyMintArtItem({...value, minted, docId, artId, index}))
       
    }
    useEffect(() => {
        getArtPiece();
    }, [])
    
  return (
    <div>
            {(item) ? (
                <div>
                    <BrowserView>
                        <Box position={'relative'} display='flex' flexDirection={'row'} justifyContent='center' alignItems={'center'}>
                            <Box  >
                                
                                    <img src={transparent} alt={item.artId} width='400px'/>
                            </Box>
                            
                            <Box position='absolute' right={-250}>
                                <DailyMetadata item={item}/>
                            </Box>
                        </Box>
                    </BrowserView>
                    <MobileView>
                        <Grid container direction={'column'} justifyContent='center' alignItems={'center'} spacing={2}>
                            <Grid item>
                                <img src={transparent} alt={item.artId} width='400px'/>
                            </Grid>
                            <Grid item>
                                <DailyMetadata item={item}/>

                            </Grid>
                        </Grid>
                    </MobileView>
                </div>
            ) : (
                <Grid item>
                    <CircularProgress>Fetching today's piece</CircularProgress>
                </Grid>
            )}
    </div>
  )
}
