import React, {useEffect, useRef, useState} from 'react'

// icons
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { isMobile } from 'react-device-detect';

import ReactAudioPlayer from 'react-audio-player';


// sounds
import sound1 from 'images/sounds/2071RT.mp3';
import sound2 from 'images/sounds/3000SO.mp3';
import sound3 from 'images/sounds/Angst.mp3';
import sound4 from 'images/sounds/Aurora.mp3';
import sound5 from 'images/sounds/Cosmo.mp3';
import sound6 from 'images/sounds/Cygnus.mp3';
import sound7 from 'images/sounds/Dune.mp3';
import sound8 from 'images/sounds/Echo.mp3';
import sound9 from 'images/sounds/Eidalon.mp3';
import sound10 from 'images/sounds/Euphoria.mp3';
import sound11 from 'images/sounds/Flora.mp3';
import sound12 from 'images/sounds/Frozen.mp3';
import sound13 from 'images/sounds/Furie.mp3';
import sound14 from 'images/sounds/GammaLeonis.mp3';
import sound15 from 'images/sounds/Janus.mp3';
import sound16 from 'images/sounds/Jupiter.mp3';
import sound17 from 'images/sounds/KeplerK.mp3';
import sound18 from 'images/sounds/Maera.mp3';
import sound19 from 'images/sounds/Meteor.mp3';
import sound20 from 'images/sounds/Mysia.mp3';
import sound21 from 'images/sounds/Nemesis.mp3';
import sound22 from 'images/sounds/Neptune.mp3';
import sound23 from 'images/sounds/Nessus.mp3';
import sound24 from 'images/sounds/Nova.mp3';
import sound25 from 'images/sounds/Nyx.mp3';
import sound26 from 'images/sounds/Oenoe.mp3';
import sound27 from 'images/sounds/Ophelia.mp3';
import sound28 from 'images/sounds/Orion.mp3';
import sound29 from 'images/sounds/Pluto.mp3';
import sound30 from 'images/sounds/Polaris.mp3';
import sound31 from 'images/sounds/Pollux.mp3';
import sound32 from 'images/sounds/Psyche.mp3';
import sound33 from 'images/sounds/Sidra.mp3';
import sound34 from 'images/sounds/Sileni.mp3';
import sound35 from 'images/sounds/Sirius.mp3';
import sound36 from 'images/sounds/Star.mp3';
import sound37 from 'images/sounds/Stardust.mp3';
import sound38 from 'images/sounds/Thrace.mp3';
import sound39 from 'images/sounds/Topia.mp3';

const sounds = [
    {sound: sound1, name: '2071RT'},
    {sound: sound2, name: '3000SO'},
    {sound: sound3, name: 'Angst'},
    {sound: sound4, name: 'Aurora'},
    {sound: sound5, name: 'Cosmo'},
    {sound: sound6, name: 'Cygnus'},
    {sound: sound7, name: 'Dune'},
    {sound: sound8, name: 'Echo'},
    {sound: sound9, name: 'Eidalon'},
    {sound: sound10, name: 'Euphoria'},
    {sound: sound11, name: 'Flora'},
    {sound: sound12, name: 'Frozen'},
    {sound: sound13, name: 'Furie'},
    {sound: sound14, name: 'GammaLeonis'},
    {sound: sound15, name: 'Janus'},
    {sound: sound16, name: 'Jupiter'},
    {sound: sound17, name: 'KeplerK'},
    {sound: sound18, name: 'Maera'},
    {sound: sound19, name: 'Meteor'},
    {sound: sound20, name: 'Mysia'},
    {sound: sound21, name: 'Nemesis'},
    {sound: sound22, name: 'Neptune'},
    {sound: sound23, name: 'Nessus'},
    {sound: sound24, name: 'Nova'},
    {sound: sound25, name: 'Nyx'},
    {sound: sound26, name: 'Oenoe'},
    {sound: sound27, name: 'Ophelia'},
    {sound: sound28, name: 'Orion'},
    {sound: sound29, name: 'Pluto'},
    {sound: sound30, name: 'Polaris'},
    {sound: sound31, name: 'Pollux'},
    {sound: sound32, name: 'Psyche'},
    {sound: sound33, name: 'Sidra'},
    {sound: sound34, name: 'Sileni'},
    {sound: sound35, name: 'Sirius'},
    {sound: sound36, name: 'Star'},
    {sound: sound37, name: 'Stardust'},
    {sound: sound38, name: 'Thrace'},
    {sound: sound39, name: 'Topia'},
]

export const CustomMusicPlayer = () => {
    const theme = useTheme();
    const player = useRef();
    const [sound, setSound] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [wasPlaying, setWasPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState('00:00')
    useEffect(() => {
        // we load the first sound
        changeSound(0)
    }, [])

    const changeSound = async  (index) => {
        const audio = sounds[index].sound;
        setCurrentTime('00:00');
        setSound({index: index, sound: audio, name: sounds[index].name, duration: '00:00'});
        player.current.src = audio;
        try {
            // we disable auto playing if on daily
            if (process.env.REACT_APP_MINT_PHASE !== '3'){
                await player.current.audioEl.current.play();
                setWasPlaying(true)
            }
        } catch ( error ){
            // ignoring
        }
    }

    const handleOnEnded = () => {
        const {index} = sound;
        if (index === sounds.length - 1)
            changeSound(0)
        else
            changeSound(index+1)
    }

    const handleNext = () => {
        const current = sound?.index || 0;
        if (current === sounds.length - 1)
            changeSound(0)
        else
            changeSound(current + 1)
    }

    const handlePrevious = () => {
        const current = sound?.index || 0;
        if (current === 0)
            changeSound(sounds.length - 1)
        else
            changeSound(current - 1)
    }

    const handlePlay = async () => {
        if (isPlaying){
            await player.current.audioEl.current.pause();
            setWasPlaying(false)
        } else{
            await player.current.audioEl.current.play();
            setWasPlaying(true)
        }
    }

    const handleCanPlay = async () => {
        if (wasPlaying){
            await player.current.audioEl.current.play();
            setWasPlaying(true)
        }
    }

    const handleLoadMetadata = (meta) => {
        const duration = getReadableTime(meta.target.duration)
        setSound({...sound, duration})
      }

    const getReadableTime = (time) => {
            const minutes = "0"+parseInt(time / 60, 10);
            const seconds = "0"+parseInt(time % 60);
            return(minutes + ":" + seconds.slice(-2))
    }

    const handleOnListen = () => {
        const currentTime = getReadableTime(player.current.audioEl.current.currentTime)
        setCurrentTime(currentTime);
    }
    
  return (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        width={(isMobile) ? '150px' : '450px'}
    >

        <ReactAudioPlayer
            src={sound?.sound}
            controls={false}
            onLoadedMetadata={handleLoadMetadata}
            onEnded={handleOnEnded}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onCanPlay={handleCanPlay}
            onListen={handleOnListen}
            onError={(error) => {console.log(error);}}
            listenInterval={1000}
            onAbort={() => setIsPlaying(false)}
            ref={player}
        />

        <Grid item>
            <IconButton onClick={handlePrevious}>
                <ArrowCircleLeftIcon   color='primary'/>
            </IconButton>
            
            <IconButton onClick={handlePlay}>
                {(isPlaying) ? (
                    <PauseIcon color='primary'/>
                ) : (
                    <PlayArrowIcon color='primary'/>
                )}
            </IconButton>

            <IconButton onClick={handleNext}>
                <ArrowCircleRightIcon color='primary'/>
            </IconButton>
        </Grid>

        {(!isMobile) && (
                <Grid item>
                    <Typography 
                        fontFamily={theme.palette.font.content}
                        textTransform='uppercase'
                        display='inline'
                    >
                        StellaLuna &nbsp;
                    </Typography>
                </Grid>
            )
        }

        <Grid item>
            <Typography
                fontFamily={theme.palette.font.content}
                variant='body2'
                display='inline'
            >
                {sound?.name}&nbsp;
            </Typography>
        </Grid>

        {(!isMobile) && (
            <Grid item>
                <Typography
                    fontFamily={theme.palette.font.content}
                >
                 - {currentTime} / {sound?.duration}
                </Typography>
            </Grid>
        )}
    </Grid>
  )
}
