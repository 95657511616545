import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material'

import { useParallax } from 'react-scroll-parallax';

import traits from 'images/about/traits.png'
import image32 from 'images/about/32.png'
import sound from 'images/about/sound.png'
import code from 'images/about/code.png'
import {isMobile} from 'react-device-detect';
import background from 'images/about/about.png'
import Container from 'common/Container';

export const About = () => {
    const theme = useTheme();
    const { ref } = useParallax({scale: [1, 1.2, 'easeInQuad'],});
  return (
    <Box marginTop={(isMobile) ? 30 : -0}>
        <div style={{position: 'relative', width:'100%'}} >
            <div ref={ref} style={{ width: '100%', overflow: 'hidden', display:'flex', objectFit: 'cover', alignItems:'center', justifyContent:'center'}}>
                <img  src={background} alt='about' style={{zIndex: -1}} width='100%'/>
            </div>
            <Container style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <Typography
                            fontFamily={theme.palette.font.headline_regular}
                            letterSpacing='0.2em'
                            variant='h4'
                            textTransform={'uppercase'}
                        >
                            About StellaLuna
                        </Typography>
                    </Grid>

                    <Grid item width={(isMobile) ? '100%' : '50%'}>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='subtitle2'
                            textAlign={'center'}
                        >
                            Are you ready to travel through time and space?<br/>
                            Come with us.<br/><br/>
                            
                            Join us as we embark on a journey to explore the possibilities of groundbreaking art
                            in conjunction with the latest NFT technology. <br/>
                            This immersive experience is the first of its kind, and you have the extraordinary opportunity to explore
                            the art with an interactive 360° experience of what it would be like to travel in time to outer space.<br/> 
                            Stellaluna, created by legendary artist, Nois7, is his first generative project.<br/>
                            
                            As you will see, every detail is created by hand. Allow your mind to be blown.<br/>
                            
                            We invite you to take the journey with us as we push the boundaries on what is possible.<br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                            
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent={(isMobile) ? 'center' : "space-between"}
                    spacing={2}
                    alignItems={(isMobile) ? 'center' : "flex-end"}
                >
                    <Grid item>
                        <img src={traits} alt='Traits' width={(isMobile) ? '125px' : '250px'}/>
                    </Grid>
                    <Grid item>
                        <img src={image32} alt='32' width={(isMobile) ? '125px' : '250px'}/>
                    </Grid>
                    <Grid item>
                        <img src={sound} alt='Sounds' width={(isMobile) ? '125px' : '250px'}/>
                    </Grid>
                    <Grid item>
                        <img src={code} alt='Code' width={(isMobile) ? '125px' : '250px'}/>
                    </Grid>
                </Grid> 
            </Container>
        </div>
    </Box>
  )
}
