import { mintTypes } from "./mintTypes";

export const setDailyMintArtItem = (item) => ({
    type: mintTypes.setDailyMintArt,
    payload: item
})


export const setDailyMintCountDownZero = (isZero) => ({
    type: mintTypes.setDailyMintCountDownZero,
    payload: isZero
})

export const setDailyMintOption = (option) => ({
    type: mintTypes.setDailyMintOption,
    payload: option
})