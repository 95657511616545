import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import WithLayout from 'WithLayout';


// Available layouts
import {Fluid, Main as MainLayout} from 'layouts';
import NotFound from 'views/NotFound';
import { useSelector } from 'react-redux';
import { Subscribe } from 'views/Subscribe/Subscribe';
import { NFT } from 'views/NFT';
import { Home } from 'views/Home';
import { Sphere } from 'views/Sphere';
import { Art } from 'views/Art';
import { FullScreen } from 'layouts/FullScreen';
import { CustomBackDrop } from 'common/CustomBackDrop';
import { Dashboard } from 'views/Dashboard';
import { MyUniverse } from 'views/MyUniverse';
import { Universes } from 'views/Universes';
import { Admin } from 'views/Admin';
import { FundWallet } from 'views/FundWallet';
import { AccessPassMint } from 'views/AccessPassMint';
import { PhysicalPrintRarity } from 'views/PhysicalPrintRarity';

export const Routes = () => {
  const {view} = useSelector(state => state.ui);

  const getViewComponent = () => {
    if (view === 'home'){
      return Home;
    }

    if (view === 'dashboard'){
      return Dashboard;
    }

    if (view === 'myUniverse'){
      return MyUniverse
    }

    return Home;
  }

  

  return (
    <div>
      <Switch>
          <Route
              exact
              path="/nft/:tokenId"
          >
            <NFT/>
          </Route>

          <Route
            exact
            path="/"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={
                    getViewComponent()
                }
                layout={MainLayout}
              />
            )}
          />

          <Route
            exact
            path="/physicalprintrarity"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={PhysicalPrintRarity}
                layout={MainLayout}
              />
            )}
          />

        <Route
          exact
          path="/subscribe"
          render={(matchProps) => (
            <WithLayout
              {...matchProps}
              component={Subscribe}
              layout={FullScreen}
            />
          )}
        />


        <Route
            path="/art"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={Art}
                layout={FullScreen}
              />
            )}
        />

        <Route
            path="/sphere"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={Sphere}
                layout={FullScreen}
              />
            )}
        />

        <Route
            exact
            path="/accesspassmint"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={AccessPassMint}
                layout={FullScreen}
              />
            )}
        />

        <Route
            exact
            path="/page-not-found"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={NotFound}
                layout={MainLayout}
              />
            )}
        />

        <Route
            path="/universes"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={Universes}
                layout={MainLayout}
              />
            )}
        />

      <Route
            path="/admin"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={Admin}
                layout={Fluid}
              />
            )}
        />
      
        <Redirect to="/page-not-found" />

      </Switch>
      <CustomBackDrop/>
      <WithLayout
        component={FundWallet}
        layout={FullScreen}
      />
    </div>
  )
}
