import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {db} from 'config/firebase';
import { getBase64FromUrl } from 'utils/base64';
import { CircularProgress } from '@mui/material';

import './Art.css'
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import { LunaAnimation } from './three/LunaAnimation';

export const Art = () => {
    const location = useLocation();  
    const [tokenId, setTokenId] = useState();
    const [metadata, setMetadata] = useState();
    const [showMetadata, setShowMetadata] = useState(false)
    const [generate, setGenerate] = useState(false);

    const dispatch = useDispatch();
    const [art, setArt] = useState()

    useEffect(() => {
      const {pathname, search} = location;
      const tokenId = pathname.replace('/art/', '');
      setTokenId(tokenId);
      
      if (search){
        const isGenerate = search.replace('?generate=', '');
        if (isGenerate === 'true')
        setGenerate(true)
      }

      document.addEventListener("keydown", handleKeyDown);
    }, [])

    const handleKeyDown = (e) => {
      if (e.keyCode === 83){
        setShowMetadata(metadata => !metadata);
      }
    }


    useEffect(() => {
      if (!isNaN(tokenId)){
        loadArt(tokenId);
      }
    }, [tokenId])
    
    const loadArt = async (tokenId) => {
      try {
        const currentRef = db.collection('drop1').doc('artEngine').collection('metadata').doc(tokenId.toString());
        const snapshot = await currentRef.get();
  
        if (snapshot.exists){
          const artDoc = snapshot.data();
          await setMetadata(artDoc);
          const {nebula, outside, sound, star, color} = artDoc;
          const nebulaBlob = await getBase64FromUrl(nebula);
          const outsideBlob = await getBase64FromUrl(outside);
          const soundBlob = await getBase64FromUrl(sound);
          const starBlob = await getBase64FromUrl(star);
  
          setArt({nebula: nebulaBlob, outside: outsideBlob, sound:soundBlob, star:starBlob, color})
        } else {
          // TODO token doesn't exists
        }
      } catch ( error ){
        dispatch(uiShowAlert('error', 'We are sorry!', 'We are having issues loading this art piece. Please try again later.'));
        console.log(error);
      }
    }

    
  return (
    
      <div>
          {(art && metadata?.artReady) ? (
            <div>
              
                  {(showMetadata) ? (
                    <div className='metadata'>
                        {metadata.attributes.map(val => (
                          <div>
                            {val.trait_type}: {val.value}
                          </div>
                        ))}
                        color: <span style={{color:art.color}}>&#9632;</span> {art.color}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className='center-screen'>
                    <LunaAnimation nebula={art.nebula} outside={art.outside} sound={art.sound} star={art.star} color={art.color} generateGif={generate} artId={tokenId} />
                  </div>
            </div>
          ) : (
              <div className='center-screen'>
                  <CircularProgress  sx={{color:"white"}} />
              </div>
          )}
      </div>
      
  )
}
