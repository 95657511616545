import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material';
import { getBase64FromUrl } from 'utils/base64';
import { removeUniverseItem, selectedUniverseItem } from 'reducers/universeReducer/universeActions';
import { updateItem } from 'reducers/blockchainReducer/blockchainActions';

export const Spheres = props => {
  const {onDragStart} = props;
  const {items} = useSelector(state => state.blockchain);
  const {items: universeItems, selected} = useSelector(state => state.universe);
  const dispatch = useDispatch();

  const loadBase64 = async () => {
    for (const item of items){
      if (!item.base64){
        const base64 = await getBase64FromUrl(item.sphereStatic);
        dispatch(updateItem({...item, base64}))
      }
    }
  }

  const handleOnClick = (item) => {
    dispatch(selectedUniverseItem(item))
  }

  const handleDoubleClick = (item) => {
    if (universeItems.find(val => val.tokenId === item.tokenId))
      dispatch(removeUniverseItem(item))
  }

  useEffect(() => {
    loadBase64();
  }, [])
  
  
  return (
    <Grid container direction='row' spacing={2}>
      {(items.map((item, index) => (
              <Grid item key={index} >
                  {(item.metadata) && (
                    <img 
                      src={item.sphereStatic} 
                      alt={item.tokenId} 
                      width='100px' 
                      style={{
                        cursor:universeItems.find(universeItem => universeItem.tokenId === item.tokenId) ? 'not-allowed' : 'grab',
                        filter:universeItems.find(universeItem => universeItem.tokenId === item.tokenId) ? 'grayscale(100%)' : 'none',
                        boder: (selected && selected.tokenId === item.tokenId) ? 1 : 0,
                        borderStyle: (selected && selected.tokenId === item.tokenId) ? 'solid' : 'none',
                        borderColor: (selected && selected.tokenId === item.tokenId) ? 'red' : 'black',
                      }}
                      onDragStart={() => onDragStart(item)}
                      onClick={() => handleOnClick(item)}
                      onDoubleClick={() => handleDoubleClick(item)}
                    />
                  )}
              </Grid>
          )))}
    </Grid>
    
  )
}

Spheres.propTypes = {
  onDragStart: PropTypes.func.isRequired,
}
