import React from 'react'
import { Grid, Typography } from '@mui/material'
import Container from 'common/Container'

import image1 from 'images/physicalPrintRarity/1.jpg'
import image2 from 'images/physicalPrintRarity/2.jpg'
import image3 from 'images/physicalPrintRarity/3.jpg'
import image4 from 'images/physicalPrintRarity/4.jpg'
import { Box, useTheme } from '@mui/system'

export const PhysicalPrintRarity = () => {
    const theme = useTheme();
  return (
    <Container>
        <Box display='flex' alignItems={'center'} paddingTop={3} justifyContent='center' textAlign={'center'}>

            <Typography
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.2em'
                variant='h4'
                gutterBottom
                textTransform={'uppercase'}
            >
                Physical Prints rarity
            </Typography>
        </Box>

        <Box textAlign={'center'} display='flex' alignItems={'center'} paddingY={3} justifyContent='center'>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum, veniam iure nesciunt vel esse quisquam maxime est necessitatibus vitae repellendus quo inventore adipisci qui alias in ullam reprehenderit omnis perferendis!
        </Box>
        <Grid
            container
            direction='row'
            spacing={4}
        >
            <Grid item xs={12} md={6}>
                <img src={image1} alt={'Physical print 1'} width='100%'/>
                <Grid container direction={'row'} justifyContent='space-between'>
                    <Grid item>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            NEBULA NAME
                        </Typography>
                    </Grid>
                    <Grid item>
                    <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            Edition of &nbsp;
                            <Box border='1px solid white' display='inline' style={{backgroundColor:'white', color: 'black'}}>
                            &nbsp;1&nbsp;
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <img src={image2} alt={'Physical print 2'} width='100%'/>
                <Grid container direction={'row'} justifyContent='space-between'>
                    <Grid item>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            NEBULA NAME
                        </Typography>
                    </Grid>
                    <Grid item>
                    <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            Edition of &nbsp;
                            <Box border='1px solid white' display='inline' style={{backgroundColor:'white', color: 'black'}}>
                            &nbsp;5&nbsp;
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <img src={image3} alt={'Physical print 3'} width='100%'/>
                <Grid container direction={'row'} justifyContent='space-between'>
                    <Grid item>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            NEBULA NAME
                        </Typography>
                    </Grid>
                    <Grid item>
                    <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            Edition of &nbsp;
                            <Box border='1px solid white' display='inline' style={{backgroundColor:'white', color: 'black'}}>
                            &nbsp;10&nbsp;
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <img src={image4} alt={'Physical print 4'} width='100%'/>
                <Grid container direction={'row'} justifyContent='space-between'>
                    <Grid item>
                        <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            NEBULA NAME
                        </Typography>
                    </Grid>
                    <Grid item>
                    <Typography
                            fontFamily={theme.palette.font.content}
                            variant='body'
                            fontWeight={700}
                            textAlign={'center'}
                        >
                            Edition of &nbsp;
                            <Box border='1px solid white' display='inline' style={{backgroundColor:'white', color: 'black'}}>
                            &nbsp;20&nbsp;
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            
        </Grid>
    </Container>
  )
}
