import React from 'react'

import { Grid, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material'


export const Social = () => {
    const theme = useTheme();
  return (
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
    >
        <Grid item>
            <Typography
                gutterBottom
                fontFamily={theme.palette.font.headline_regular}
                letterSpacing='0.1em'
                variant='h4'
                textTransform={'uppercase'}
                textAlign={'center'}
            >
                Socials
            </Typography>
        </Grid>

        <Grid item>
            <Typography
                fontFamily={theme.palette.font.content}
                textAlign='center'
            >
                To keep up to date with all latest announcements, launches,
                giveaways and more please visit our {` `}
                <Link component="a" href="https://discord.com/invite/zpY57tTGqY" color="textPrimary" target='_blank' rel='noopener noreferrer' >
                    DISCORD
                </Link>
                , {` `}
                <Link component="a" href="https://www.instagram.com/stellaluna_nft" color="textPrimary" target='_blank' rel='noopener noreferrer' >
                    INSTAGRAM
                </Link>
                {` `} & {` `}
                <Link component="a" href="https://twitter.com/stellaluna_nft" color="textPrimary" target='_blank' rel='noopener noreferrer' >
                    TWITTER
                </Link>
                .
            </Typography>
        </Grid>
    </Grid>
  )
}
