export const pages = [
  {
    title: 'Art',
    id: 'art-pages',
    pages: [
      {
        title: 'Review',
        id: 'artReview',
      },
      {
        title: 'Artist Selected',
        id: 'artistSelected',
      },
      {
        title: 'Traits',
        id: 'traits',
      }
    ],
  },
  {
    title: 'NFT',
    id: 'nft-pages',
    pages: [
      {
        title: 'Issued',
        id: 'issuedNFT',
      },
      {
        title: 'Next 5',
        id: 'nftNext5',
      },
    ],
  }
];
