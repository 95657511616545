import React, { useState } from 'react'

import { Box, Button, Typography, useTheme, Slide, Dialog, IconButton, Grid} from '@mui/material'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import ReactPlayer from 'react-player'

import nebula from 'images/preview/nebula.jpg'
import star from 'images/preview/star.png'
import video from 'images/preview/NFTExperience.mp4'
import { PreviewAnimation } from './Components/PreviewAnimation'

export const Preview = () => {
    const [show, setShow] = useState(false)
    const theme = useTheme();

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const handleClick = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false);
        window.location.reload(false);
      };
  
      return (
        <div>
            <Grid
             container
             direction="column"
             justifyContent="center"
             alignItems="center"
            >
                <Grid item>
                    <Typography 
                        fontFamily={theme.palette.font.headline_regular}
                        letterSpacing='0.2em'
                        variant='h4'
                        textTransform={'uppercase'}
                        textAlign={'center'}
                    >
                        Experience the NFT
                    </Typography>
                </Grid>

                <Grid item onClick={handleClick} style={{cursor:'pointer'}}>
                    <ReactPlayer
                        url={video}
                        playing
                        loop
                        muted
                        width='100%'
                        height='100%'
                    />
                </Grid>
            </Grid>

            <Dialog
                fullScreen
                open={show}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor:'black' }} elevation={0} >
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Close
                        </Typography>
                    </Toolbar>
                </AppBar>

                <PreviewAnimation nebula={nebula} star={star} withControls={show}/>
            </Dialog>

        </div>
  )
}
