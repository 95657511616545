import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { Button,  Grid } from '@mui/material'
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import { loadAddress, submitAddress } from 'reducers/blockchainReducer/blockchainActions';

export const AddressForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const {address} = useSelector(state => state.walletProvider);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(null)

    const getAddress = async () => {
        try {
            const result = await loadAddress(address);
            setFormData(result);
        } catch ( error ){
            // ignoring
        }
    }
    useEffect(() => {
      if (address){
        getAddress();
      }
    }, [address])
    
    const onSubmit = async (data) => {
        try {
            await submitAddress(address, data)
            dispatch(uiShowAlert('success', 'Address', 'Submitted successfully.'))
        } catch ( error ){
            dispatch(uiShowAlert('error', 'Address', 'There was an error submitting your address. Please try again in a few minutes.'))
        }
    }

  return (
       <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
            container
            direction= 'row'
            justifyContent="space-around"
            spacing={2}
        >
            <Grid item>
                <Grid container direction='column' alignItems="flex-end" spacing={1}>
                    <Grid item>
                        First Name* &nbsp;&nbsp;<input defaultValue={formData?.firstName} label="First Name" {...register("firstName", { required: true })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.firstName ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        Last Name* &nbsp;&nbsp;<input defaultValue={formData?.lastName} label="Last Name" {...register("lastName", { required: true })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.lastName ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        Email* &nbsp;&nbsp;<input defaultValue={formData?.email} label="Email" {...register("email", { required: true , pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.email ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        Address line 1* &nbsp;&nbsp;<input defaultValue={formData?.addressLine1} {...register("addressLine1", { required: true })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.addressLine1 ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        Address line 2 &nbsp;&nbsp;<input defaultValue={formData?.addressLine2} {...register("addressLine2", { required: false })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.addressLine2 ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    
                </Grid>    
            </Grid>

            <Grid item>
                <Grid container direction='column' alignItems="flex-end"  spacing={1}>
                    <Grid item>
                        City* &nbsp;&nbsp;<input defaultValue={formData?.city} {...register("city", { required: true })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.city ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        State/Provice/Region* &nbsp;&nbsp;<input defaultValue={formData?.state} {...register("state", { required: true })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.state ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        Country* &nbsp;&nbsp;<input defaultValue={formData?.country} {...register("country", { required: true })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.country ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        Telephone &nbsp;&nbsp;<input defaultValue={formData?.telephone} {...register("telephone", { required: false })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.telephone ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        Comments &nbsp;&nbsp;<input defaultValue={formData?.comments} {...register("comments", { required: false })} style={{background: 'transparent', color:'white', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.comments ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item paddingTop={2} marginRight={2}>
                        <Button onClick={handleSubmit(onSubmit)} sx={{color: 'black', backgroundColor:'white', ":hover":{backgroundColor: 'black', color:'white'}}}>
                            SUBMIT
                        </Button>
                    </Grid>
                </Grid>    
            </Grid>
        </Grid>
    </form>
  )
}
